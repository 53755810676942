import {LoginActionTypes, UserAccountState} from "./types";
import {SignInAction} from "./actions";
import {Reducer} from "redux";

const initialState: UserAccountState = {
    signingIn: false
};

const reducer: Reducer<UserAccountState> = (state = initialState, action: SignInAction): UserAccountState => {
    switch (action.type) {
        case LoginActionTypes.REQUEST_TOKEN: {
            return {
                ...state,
                signingIn: true
            }
        }
        case LoginActionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                token: action.payload,
                signingIn: false
            }
        }
        case LoginActionTypes.LOGIN_ERROR: {
            return {
                ...state,
                loginError: action.payload,
                signingIn: false
            }
        }
        case LoginActionTypes.LOGOUT: {
            return {
                ...state,
                ...initialState
            }
        }
        default: {
            return state
        }
    }
};

export {reducer as signInReducer};