import {all, apply, call, fork, put, takeEvery, takeLatest} from 'redux-saga/effects'
import {LoginActionTypes} from './types'
import {authUserAction, userAuthError, userAuthSuccess, userLogoutAction,} from './actions'
import axiosClient from "../../utils/axiosClient";
import {Routes} from "../../route/RouteElem";
import { push } from 'connected-react-router'
import {appSecurityService, Credentials} from "../../utils/securityService";
import {requestProfile} from "../profile/actions";

function* handleSignInRequest(action: ReturnType<typeof authUserAction>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res = yield apply(axiosClient, axiosClient.postData, ['/signIn', {login: action.payload.login, password: action.payload.password}]);

        if (res.error) {
            yield put(userAuthError(res.error))
        } else {
            yield call(appSecurityService.setupCredentials, new Credentials(
                action.payload.login,
                res.data.id
            ));
            yield put(userAuthSuccess(res.data));
            yield put(requestProfile());
            yield put(push('/'))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(userAuthError(err.stack!))
        } else {
            yield put(userAuthError('An unknown error occurred.'))
        }
    }
}
//
// function* handleGetUserRequest(action: ReturnType<typeof requestAuthUserInfo>) {
//     try {
//         // To call async functions, use redux-saga's `call()`.
//         const res = yield apply(axiosClient,
//             axiosClient.getData,
//             ['/me']);
//
//         if (res.error) {
//             yield put(userAuthError(res.error))
//         } else {
//             yield call(appSecurityService.setupCredentials, new Credentials(
//                 action.payload.login,
//                 res.data.token.id
//             ));
//             yield put(userAuthSuccess(res.data));
//         }
//     } catch (err) {
//         if (err instanceof Error) {
//             yield put(userAuthError(err.stack!))
//         } else {
//             yield put(userAuthError('An unknown error occured.'))
//         }
//     }
// }


function* handleLogoutRequest(action: ReturnType<typeof userLogoutAction>) {
    yield call(appSecurityService.clearCredentials);
    yield put(push(Routes.loginRoute.path));
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleSignInRequest()` saga above.
function* watchLoginRequest() {
    yield takeEvery(LoginActionTypes.REQUEST_TOKEN, handleSignInRequest)
}

function* watchLogoutRequest() {
    yield takeLatest(LoginActionTypes.LOGOUT, handleLogoutRequest)
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* signInSaga() {
    yield all([watchLoginRequest, watchLogoutRequest].map(fork))
}

export default signInSaga
