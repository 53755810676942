import _ from "lodash";
import {all, fork, put, takeEvery} from 'redux-saga/effects'
import {MeasurementsActionTypes} from './types'
import {
    fetchMeasurementsListAction,
    fetchMeasurementsListErrorAction,
    fetchMeasurementsListSuccessAction, setSelectedMeasurement, setSelectedMeasurementImage
} from './actions';
import axiosClient from "../../utils/axiosClient";


function* handleDataFetchRequest(action: ReturnType<typeof fetchMeasurementsListAction>) {
    try {

        const url = '/measurements';
        const res = yield axiosClient.getData(url, {params: action.payload.filter});

        if (res.error) {
            yield put(fetchMeasurementsListErrorAction(res.error))
        } else {
            yield put(fetchMeasurementsListSuccessAction(res.data));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchMeasurementsListErrorAction({
                errorMessage: err.stack!
            }))
        } else {
            yield put(fetchMeasurementsListErrorAction({
                errorMessage: 'An unknown error occurred.'
            }))
        }
    }
}
function* handleMeasurementSelectedRequest(action: ReturnType<typeof setSelectedMeasurement>) {
    if (action.payload.selectedMeasurement?.images && !_.isEmpty(action.payload.selectedMeasurement?.images)) {
        try {
            const url = '/measurements/'+action.payload.selectedMeasurement?.id+"/"+action.payload.selectedMeasurement.images[0];
            const res = yield axiosClient.getData<Int8Array>(url, {
                responseType: "blob"
            });
            if (!res.error) {
                yield put(setSelectedMeasurementImage(res.data));
            }
        } catch (err) {
            alert(err);
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleSignInRequest()` saga above.
function* watchFetchRequests() {
    yield takeEvery(MeasurementsActionTypes.REQUEST_MEASUREMENTS, handleDataFetchRequest)
}
function* watchMeasurementSelected() {
    yield takeEvery(MeasurementsActionTypes.SELECT_MEASUREMENT, handleMeasurementSelectedRequest)
}
// We can also use `fork()` here to split our saga into multiple watchers.
function* measurementsSaga() {
    yield all([
        watchFetchRequests,
        watchMeasurementSelected
    ].map(fork))
}


export default measurementsSaga;
