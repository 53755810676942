import {ProfileActionTypes, ProfileState} from "./types";
import {ProfileFetchAction} from "./actions";

const reducer = (state: ProfileState = {}, action: ProfileFetchAction): ProfileState => {
    switch (action.type) {
        case ProfileActionTypes.REQUEST_PROFILE: {
            return state
        }
        case ProfileActionTypes.PROFILE_SUCCESS: {
            return {
                ...state,
                ...action.payload
            }
        }
        case ProfileActionTypes.PROFILE_FAILURE: {
            return state
        }
        default: {
            return state
        }
    }
};

export { reducer as profileReducer };