import {LongKeyIdentifiable} from "./base/Identifiable";

export class MeasuredData {
    key: string;
    data: string;
}
export class Measurement extends LongKeyIdentifiable {
    measureType?: string;
    deviceId?: string;
    chipVersion?: string;
    revision?: string;
    phoneId?: string;
    fio?: string;
    address?: string;
    appVersion?: string;
    measureTime?: string;
    quality?: number;
    latitude?: number;
    longitude?: number;
    userId?: number;
    measuredData?: MeasuredData[];
    images?: string[];
}

export class UserAuthToken {
    public id?: string;
    public userId?: number;
    public host?: string;
    public lastRequestPath?: string;
    public creationTime: Date;
    public lastRequestTime?: Date;
    public expiryTime: Date;
}

export class UserAccount extends LongKeyIdentifiable {
    public loginInfo?: {
        credentialProvider?: string,
        login?: string
    };
    public email?: string;
    public name?: string;
    public registerDate: Date;
    public disabled: boolean;
    public roles: string[];
    public password?: {
        main?: string
        confirm?: string
    };
}

export interface Profile {
    readonly user: UserAccount
    readonly roles?: string[]
}
