// import { createStyles } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import * as React from "react";
import {Map, YMaps, ZoomControl} from "react-yandex-maps";
import history from "../../History";
import {AdminLayout} from '../../layout';
import {
    Button,
    Card,
    CardContent, createStyles,
    FormControl, Grid,
    MenuItem, Paper,
    Select,
    TextField,
    Typography, withStyles, WithStyles
} from "@material-ui/core";
import axiosClient from "../../utils/axiosClient";

export interface StateProps {
    measureType?: string;
    measurementFile?: File;
    measureTime: Date;
    fio?: string;
    address?: string;
    latitude?: number;
    longitude?: number;
}

const styles = createStyles({
    formControl: {
        width: '100%',
        marginTop: 10
    },
    formActions: {
        display: 'flex',
        marginTop: 15,
        alignItems: 'center',
    }
});

interface ImportMeasurementProps extends StateProps {

}

class ImportMeasurement extends React.Component<ImportMeasurementProps & WithStyles<typeof styles>, any> {
    state: Readonly<StateProps> = {
        measureType: 'UV',
        measureTime: new Date()
    }

    private mapRef: any = React.createRef();
    private ymapsRef: any = React.createRef();

    sendDataToServer() {
        const formData = new FormData();
        for (const name in this.state) {
            console.log("Adding attr " + name);
            if (this.state[name] instanceof Date) {
                formData.append(name, this.state[name].toISOString());
            } else formData.append(name, this.state[name]);
        }

        axiosClient.postData("/measurements/import", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((resp) => {
            if (resp.status >= 200 && resp.status <=204) history.push("/");
        })
    }

    handleChange(field: keyof StateProps) {
        return (event: React.ChangeEvent<{ value: string | null | undefined }>) =>
            this.setState({[field]: event.target.value})
    }

    handleDateChange(field: keyof StateProps) {
        return (date: MaterialUiPickersDate | null, value?: string | null) => {
            this.setState({[field]: date})
        }
    }

    handleFileChange(field: keyof StateProps) {
        return (e: React.ChangeEvent<HTMLInputElement>) =>
            this.setState({
                [field]: e.currentTarget.files ? e.currentTarget.files[0] : null
            })
    }

    addSearchControlEvents = () => {
        const map = this.mapRef.current;
        const ymaps = this.ymapsRef.current;

        const searchControl = new ymaps.control.SearchControl({
            options: {
                float: "right",
                floatIndex: 300,
                provider: "yandex#search",
                placeholderContent: "Поиск мест и адресов",
                maxWidth: 450,
                size: "large"
            }
        });
        map.controls.add(searchControl);

        searchControl.events.add("resultselect", (e: any) => {
            const searchCoords = searchControl.getResponseMetaData().SearchResponse
                .Point.coordinates;
            this.setState({
                address: searchControl.getResponseMetaData().SearchRequest.request,
                latitude: searchCoords[1],
                longitude: searchCoords[0],
            });
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <AdminLayout>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Импорт нового измерения
                        </Typography>
                        <Grid container spacing={8}>
                            <Grid xs={12} md={6} >
                                <Paper>
                                    <YMaps query={{apikey: '034ed834-855a-47c9-a102-8cd5d9e92f65'}} >
                                        <Map defaultState={{ center: [55.75, 37.57], zoom: 9}} width='100%' height='700px'
                                             instanceRef={this.mapRef}
                                             onLoad={(ymapsInstance: any) => {
                                                 this.ymapsRef.current = ymapsInstance;
                                                 this.addSearchControlEvents();
                                             }}
                                             modules={["control.SearchControl"]}
                                        >
                                            <ZoomControl
                                                options={{ float: "none", position: { top: 100, right: 10 } }}
                                            />
                                        </Map>
                                    </YMaps>
                                </Paper>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <form style={{padding: 20}}>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            id="measureType"
                                            value={this.state.measureType}
                                            onChange={this.handleChange("measureType")}
                                            // className={classes.formControl}
                                        >
                                            <MenuItem value="UV">
                                                <em>UV</em>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <TextField id="fio"
                                                   name="fio"
                                                   label="ФИО"
                                                   // className={classes.formControl}
                                                   onBlur={this.handleChange("fio")}
                                        />
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <TextField id="address"
                                                   name="address"
                                                   label="Адрес"
                                                   value={this.state.address}
                                                   InputProps={{
                                                       readOnly: true,
                                                   }}
                                                   InputLabelProps={{ shrink: !!this.state.address }}
                                                   required
                                        />
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <Grid container justify="space-around" spacing={3}>
                                            <Grid item xs={6}>
                                                <TextField id="latitude"
                                                           name="latitude"
                                                           label="Ш"
                                                           value={this.state.latitude}
                                                           InputProps={{
                                                               readOnly: true,
                                                           }}
                                                           InputLabelProps={{ shrink: !!this.state.latitude }}
                                                           required
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField id="longitude"
                                                           name="longitude"
                                                           label="Д"
                                                           value={this.state.longitude}
                                                           InputProps={{
                                                               readOnly: true,
                                                           }}
                                                           InputLabelProps={{ shrink: !!this.state.longitude }}
                                                           required
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around" spacing={3}>
                                                <Grid item xs={6}>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        variant="inline"
                                                        format="dd.MM.yyyy"
                                                        margin="normal"
                                                        label="Дата измерения"
                                                        value={this.state.measureTime}
                                                        onChange={this.handleDateChange('measureTime')}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'Изменить дату',
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                   <KeyboardTimePicker
                                                        margin="normal"
                                                        id="time-picker"
                                                        label="Время измерения"
                                                        value={this.state.measureTime}
                                                        onChange={this.handleDateChange('measureTime')}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'Изменить время',
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <input
                                            name="measurementFile"
                                            type="file"
                                            // className={classes.formControl}
                                            onChange={this.handleFileChange("measurementFile")}>
                                        </input>
                                    </FormControl>
                                </form>
                                <div className={classes.formActions}>
                                    <Button onClick={() => this.sendDataToServer()} color="primary" disabled={!(this.state.address && this.state.fio && this.state.measureTime && this.state.measurementFile)}>
                                        Сохранить
                                    </Button>
                                    <Button onClick={() => history.push("/")} color="secondary">
                                        Отмена
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </AdminLayout>
        );
    }
}

export default withStyles(styles)(ImportMeasurement) as any;
