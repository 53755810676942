import {Metadata} from "./Metadata";

interface ICredentials {
    readonly user: string;
    readonly token: string;
}

export class Credentials implements ICredentials {
    user: string;
    token: string;

    constructor(user: string, token: string) {
        this.user = user;
        this.token = token;
    }
}

class SecurityService {
    setupCredentials(credentials: Credentials): void {
        localStorage.setItem(Metadata.HttpHeaders.XAuthUser, credentials.user);
        localStorage.setItem(Metadata.HttpHeaders.XAuthToken, credentials.token);
    }

    getCredentials(): Credentials | null {
        return (!!localStorage.getItem(Metadata.HttpHeaders.XAuthUser) &&
            !!localStorage.getItem(Metadata.HttpHeaders.XAuthToken)) ?
            new Credentials(localStorage.getItem(Metadata.HttpHeaders.XAuthUser)!,
                localStorage.getItem(Metadata.HttpHeaders.XAuthToken)!) : null;
    }

    invalidateToken(): void {
        localStorage.removeItem(Metadata.HttpHeaders.XAuthToken);
    }

    clearCredentials(): void {
        localStorage.removeItem(Metadata.HttpHeaders.XAuthUser);
        localStorage.removeItem(Metadata.HttpHeaders.XAuthToken);
    }

    isAuthenticated(): boolean {
        return !!localStorage.getItem(Metadata.HttpHeaders.XAuthToken);
    }
}

export const appSecurityService = new SecurityService();