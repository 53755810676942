import React from 'react';
import { makeStyles } from '@material-ui/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import {Dialog} from "@material-ui/core";
import ReactPlayer from "react-player";

const useStyles = makeStyles({
    root: {
        width: 500,
        backgroundColor: '#efefef',
        '@media (max-width: 600px)' : {
            width: '100%',
        }
    },
    panelContainer: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: 60,
        '@media (max-width: 600px)' : {
            display: 'block',
            position: 'relative'
        }
    },
    bottomItem: {
        fontSize: 8
    }
});

export default function LiqwizBottomPanel() {
    const classes = useStyles();
    const [videoUrl, setVideoUrl] = React.useState<any>(null);

    return (
        <div>
            {<Dialog
                open={!!videoUrl}
                onClose={() => setVideoUrl(null)}
            >
                <ReactPlayer url={process.env.PUBLIC_URL + videoUrl} playing />
            </Dialog>}
            <div className={classes.panelContainer}>
                <BottomNavigation
                    onChange={(event, newValue) => {
                        if (newValue === 0) setVideoUrl("/videos/liqwiz_webapp_video.mov");
                        else if (newValue === 1) setVideoUrl("/videos/liqwiz_phone_video.mp4");
                        else if (newValue === 2) {
                            window.open(process.env.PUBLIC_URL + "/apps/app-release_1_16.apk", '_blank')
                        }
                    }}
                    showLabels
                    className={classes.root}
                >
                    <BottomNavigationAction className={classes.bottomItem} label="Использование Web-приложения" icon={<OndemandVideoIcon />} />
                    <BottomNavigationAction className={classes.bottomItem} label="Мобильное приложение" icon={<VideoLibraryIcon />} />
                    <BottomNavigationAction className={classes.bottomItem} label="Скачать apk" icon={<SmartphoneIcon />} />
                </BottomNavigation>
            </div>
        </div>
    );
}

