import {action} from "typesafe-actions";
import {ProfileActionTypes} from "./types";
import {Action} from "redux";
import {Profile} from "../../model/entities";
import {ErrorDataContainer} from "../../model/ErrorDataContainer";

export interface ProfileFetchAction extends Action<ProfileActionTypes> {
    payload?: any;
}

export const requestProfile = () => {
    return action(ProfileActionTypes.REQUEST_PROFILE);
};
export const requestProfileSuccess = (profile: Profile) => {
    return action(ProfileActionTypes.PROFILE_SUCCESS, profile);
};
export const requestProfileError = (error: ErrorDataContainer) => {
    return action(ProfileActionTypes.PROFILE_FAILURE);
};
