import {ConnectedRouter} from "connected-react-router";
import { History } from "history";
import * as _ from "lodash";
import * as React from "react";
import {connect, ReactReduxContext} from "react-redux";
import {Route, Switch} from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import {Profile, UserAuthToken} from "../model/entities";
import {AppState} from "../store";
import {requestProfile} from "../store/profile/actions";
import {appSecurityService} from "../utils/securityService";
import {PrivateRouteElem, RouteElem, Routes} from "./RouteElem";
import {SecuredRoute} from "./SecuredRoute";
import {ConditionalRoute} from "./ConditionalRoute";


interface SelfProps {
    history: History;
}

interface RouterStateProps {
    profile?: Profile;
    token?: UserAuthToken;
}

interface DispatchProps {
    getProfile?: typeof requestProfile;
}

class AllRouter extends React.Component<SelfProps & RouterStateProps & DispatchProps> {
    public componentDidMount(): void {
        const {profile, getProfile} = this.props;
        if (!profile && !!getProfile) {
            getProfile();
        }
    }

    public render() {
        const isUserAuthenticated = appSecurityService.isAuthenticated();
        const { profile, token, history } = this.props;
        const isUserInRole = (roleName: string) => !!profile && !!profile.roles && _.includes(profile.roles, roleName);

        return (
            <ConnectedRouter history={history} context={ReactReduxContext}>
                <Switch>
                    {
                        <ConditionalRoute routeCondition={!token || !isUserAuthenticated} redirectTo={"/"} component={Routes.loginRoute.container} exact path={Routes.loginRoute.path}/>
                    }
                    {
                        _.map(Routes.publicRoutes, (elem: RouteElem, key) =>
                            elem.exact ?
                                <Route exact path={elem.path} component = {elem.container} key={key} /> :
                                <Route path={elem.path} component = {elem.container} key={key} />,
                        )
                    }
                    {
                        _.map(_.filter(Routes.securedRoutes, (r: PrivateRouteElem) => {
                                return !r.roles || _.isEmpty(r.roles) || !!_.find(r.roles, isUserInRole);
                            }), (elem: PrivateRouteElem, key) =>
                                elem.exact ?
                                    <SecuredRoute exact={true} path={elem.path} key={key} isUserAuthenticated={isUserAuthenticated} component = {elem.container}/> :
                                    <SecuredRoute path={elem.path} key={key} isUserAuthenticated={isUserAuthenticated} component = {elem.container}/>,
                        )
                    }
                </Switch>
            </ConnectedRouter>
        );
    }
}

function mapStateToProps(state: AppState): RouterStateProps {
    let profile: Profile | undefined;
    if (state.profile && state.profile.user) {
        profile = {
            user: state.profile.user!,
            roles: state.profile.roles,
        };
    }
    return {
        profile,
        token: state.auth ? state.auth.token : undefined,
    };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {...bindActionCreators({
            getProfile: requestProfile,
        }, dispatch)};}

export default connect<RouterStateProps, DispatchProps, SelfProps>(mapStateToProps, mapDispatchToProps)(AllRouter);
