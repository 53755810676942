import * as React from "react";
import {AdminLayout} from '../../layout';
import {YMaps, Map, Clusterer, Placemark} from 'react-yandex-maps';
import {Grid, Paper} from "@material-ui/core";
import {Measurement} from "../../model/entities";
import axiosClient from "../../utils/axiosClient";
import {MeasurementsOrSelected} from "./MeasurementsOrSelected";
import {fetchMeasurementsListAction, setSelectedMeasurement, closeMeasurementSelection} from "../../store/measurements/actions";
import {AppState} from "../../store";
import {Dispatch} from "redux";
import {connect} from "react-redux";

export interface StateProps {
    measurements: Measurement[];
    selectedMeasurement?: Measurement;
    selectedMeasurementImageData?: Blob;
}
export interface DispatchProps {
    fetchAllData: typeof fetchMeasurementsListAction
    setSelectedMeasurement: typeof setSelectedMeasurement
    closeMeasurementSelection: typeof closeMeasurementSelection
}

interface HomeProps extends StateProps, DispatchProps {

}

class Home extends React.Component<HomeProps> {
    componentDidMount(): void {
        this.props.fetchAllData();
    }

    render() {
        const {measurements,
            selectedMeasurement,
            selectedMeasurementImageData,
            setSelectedMeasurement,
            closeMeasurementSelection} = this.props;
        return (
            <AdminLayout>
                <YMaps>
                    <Grid container spacing={8}>
                         <Grid item xs={12} md={6}>
                             <Paper>
                                 <Map defaultState={{ center: [55.75, 37.57], zoom: 9}} width='100%' height='700px'>
                                     <Clusterer
                                         options={{
                                             preset: 'islands#invertedVioletClusterIcons',
                                             groupByCoordinates: false,
                                         }}
                                     >
                                         {measurements.filter((m) => !!m.latitude && !!m.longitude).map((measurement, index) => (
                                             <Placemark key={measurement.id}
                                                        geometry={[measurement.latitude!!, measurement.longitude!!]}
                                             />
                                         ))}
                                     </Clusterer>

                                 </Map>
                             </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MeasurementsOrSelected
                                measurements={measurements}
                                selectedMeasurement={selectedMeasurement}
                                selectedMeasurementImageData={selectedMeasurementImageData}
                                onItemSelect={(item: Measurement) => {
                                    axiosClient.getData<Measurement>("/measurements/"+item.id).then((r) =>
                                        setSelectedMeasurement(r.data)
                                    )
                                }}
                                onItemViewClose={(item: Measurement) => closeMeasurementSelection(item)}
                            />
                        </Grid>
                    </Grid>
                </YMaps>
            </AdminLayout>
        );
    }
}

function mapStateToProps(state: AppState): StateProps {
    return {
        measurements: !!state.measurements ? state.measurements.measurementsList : [],
        selectedMeasurement: !!state.measurements ? state.measurements.selectedMeasurement : undefined,
        selectedMeasurementImageData: state.measurements?.selectedMeasurementImageData ? state.measurements?.selectedMeasurementImageData : undefined
    };
}


function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {
        fetchAllData: (filter?: any) => dispatch(fetchMeasurementsListAction(filter)),
        setSelectedMeasurement: (measurement: Measurement) => dispatch(setSelectedMeasurement(measurement)),
        closeMeasurementSelection: (measurement?: Measurement) => dispatch(closeMeasurementSelection(measurement))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
