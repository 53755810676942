import TextField from "@material-ui/core/TextField/TextField";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {ArrowForward} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import * as React from "react";
import {ChangeEvent} from "react";
import {Dispatch} from "redux";
import {Metadata} from "../../utils/Metadata";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {authUserAction} from "../../store/security/actions";
import {connect} from "react-redux";
import {AppState} from "../../store";
import {get} from 'lodash';

const useStyles = makeStyles({
    textField: {
        color: '#555'
    },
    textInputLabel: {
        color: '#888',
        fontSize: '1.1em'
    },
    underlineStyle: {
        color: '#666',
        borderBottom: '2px solid white',
        '&:after': {
            // The MUI source seems to use this but it doesn't work
            borderBottom: '2px solid grey',
        },
    },
    underlineFocusStyle: {
        color: '#222',
        borderBottom: 'none',
    },
    button: {
        marginTop: '20px',
        width: '96px',
        height: '96px',
        padding: '12px',
        color: '#252c55',
        '&:hover': {
            // The MUI source seems to use this but it doesn't work
            color: '#333'
        }
    },
    buttonIcon: {
        fontSize: '2.5em',
    }
});

const processStyle = {
    marginTop: '20px'
};

const formErrorStyle = {
    color: "#ff0000",
};

interface StateProps {
    login: string;
    password: string;
    loading: boolean;
    fieldErrors?: {[id: string]: string};
    loginError?: string;
}

interface DispatchProps {
    onAuthUserAction: typeof authUserAction;
}

type AllProps = StateProps & DispatchProps;

interface LoginFormProps extends AllProps {
    children?: any;
}

export class LoginState {
    public login: string;
    public password: string;
    public loading: boolean;
    public fieldErrors?: {[id: string]: string};
    public loginError?: string;
}

const updateState = <T extends keyof LoginState>(newStateObj: Partial<LoginState>) => (
    prevState: LoginState,
): LoginState => {
    const newState = Object.assign({...prevState}, newStateObj ? newStateObj : {});
    if (!!newStateObj.login && !!newState.fieldErrors && !!newState.fieldErrors.login) { delete(newState.fieldErrors!.login); }
    if (!!newStateObj.password && !!newState.fieldErrors && !!newState.fieldErrors.password) { delete(newState.fieldErrors!.password); }
    if (!!newState.loginError && (!!newStateObj.login || !!newStateObj.password)) { delete(newState.loginError); }
    return newState;
};

interface LoginActivitiesHandler {
    onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleLoginSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

function LoginForm(props: LoginFormProps & LoginState & LoginActivitiesHandler) {
    const classes = useStyles(props);
    const {fieldErrors, loginError, loading, handleLoginSubmit, onInputChange} = props;
    return (
        <form name="loginForm"
              className="login-form-group" onSubmit={handleLoginSubmit}>
            <div className="login-form">
                <TextField
                    id="login-input"
                    label="Логин"
                    name="login"
                    className={classes.textField}
                    InputLabelProps={{
                        className: classes.textInputLabel,
                    }}
                    InputProps={{
                        classes: {
                            underline: classes.underlineStyle,
                            focused: classes.underlineFocusStyle,
                        },
                    }}
                    margin="normal"
                    disabled={loading}
                    required={true}
                    onChange={onInputChange}
                    error={!!fieldErrors && !!fieldErrors.login}
                    helperText={(!!fieldErrors && !!fieldErrors.login) ? fieldErrors.login : undefined}
                />
            </div>
            <div className="login-form">
                <TextField
                    id="password-input"
                    type="password"
                    label="Пароль"
                    name="password"
                    disabled={loading}
                    className={classes.textField}
                    InputLabelProps={{
                        className: classes.textInputLabel,
                    }}
                    InputProps={{
                        classes: {
                            underline: classes.underlineStyle,
                            focused: classes.underlineFocusStyle,
                        },
                    }}
                    margin="normal"
                    required={true}
                    onChange={onInputChange}
                    error={!!fieldErrors && !!fieldErrors.password}
                    helperText={(!!fieldErrors && !!fieldErrors.password) ? fieldErrors.password : undefined}
                />
            </div>
            <div className="login-button-group">{
                loading ? (
                    <div style={processStyle}>
                        <CircularProgress />
                    </div>
                ) : (
                    <IconButton type = 'submit'
                                className={classes.button}
                                aria-label="SignIn">
                        <ArrowForward className={classes.buttonIcon}/>
                    </IconButton>
                )
            }
            </div>
            {
                !!loginError ? (
                    <div className="login-form">
                        <span style={formErrorStyle}>{loginError}</span>
                    </div>
                ) : null
            }
        </form>
    );
}

class ConnectedLoginForm extends React.Component<DispatchProps & StateProps, LoginState> {

    public static getDerivedStateFromProps(props: StateProps, state: LoginState): LoginState {
        return {
            ...state,
            loading: props.loading,
            fieldErrors: props.fieldErrors,
            loginError: props.loginError,
        };
    }
    constructor(props: LoginFormProps, context: any) {
        super(props, context);
        this.state = {
            login: localStorage.getItem(Metadata.HttpHeaders.XAuthUser) ? localStorage.getItem(Metadata.HttpHeaders.XAuthUser)! : "",
            password: "",
            loading: false,
        };
    }

    public handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.currentTarget;
        const value = target.value;
        const name = target.name as keyof LoginState;

        this.setState(updateState({[name]: value}));
    }

    public render() {
        const handlers = {
            onInputChange: this.handleInputChange.bind(this),
            handleLoginSubmit: this.handleSubmit.bind(this),
        };
        const nestedProps = {
            ...this.props,
            ...this.state,
            ...handlers,
        };
        return (
            <LoginForm {...nestedProps} />
        );
    }

    private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.setState(updateState({
            loading: true,
        }));
        this.props.onAuthUserAction(this.state.login, this.state.password);
    }
}

function mapStateToProps(state: AppState): StateProps {
    return {
        login: (!!state.profile && !!state.profile.user) ? get(state, ["profile", "user", "loginInfo", "login"], "") : "",
        password: "",
        loading: !!state.auth ? state.auth.signingIn : false,
        loginError: (!!state.auth && !!state.auth.loginError) ? state.auth!.loginError!.errorMessage : undefined,
    };
}


function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {
        onAuthUserAction: (login: string, password: string) => dispatch(authUserAction(login, password)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedLoginForm);
