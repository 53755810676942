import * as React from "react";
import {ReactNode} from "react";
import {ICellRendererParams} from 'ag-grid-community';

export interface ActionsCellProps extends ICellRendererParams {
    readonly actions?: (item: any) => ReactNode[]
}

export class ActionsCellRenderer extends React.Component<ActionsCellProps> {
    render(): ReactNode {
        return (
            this.props.actions ? <span>{this.props.actions(this.props.data)}</span> : null
        );
    }
}