import {Measurement} from "../../model/entities";

export interface MeasurementsModelState {
    measurementsList: Measurement[]
    selectedMeasurement?: Measurement,
    selectedMeasurementImageData?: Blob
}

export enum MeasurementsActionTypes {
    REQUEST_MEASUREMENTS = '@@measurements/REQUEST_LIST',
    MEASUREMENTS_GET_SUCCESS = '@@measurements/MEASUREMENTS_GET_SUCCESS',
    MEASUREMENT_IMAGE_GET_SUCCESS = '@@measurements/MEASUREMENT_IMAGE_GET_SUCCESS',
    MEASUREMENTS_GET_FAILURE = '@@measurements/MEASUREMENTS_GET_FAILURE',
    SELECT_MEASUREMENT = '@@measurements/SELECT_MEASUREMENT',
    DESELECT_MEASUREMENT = '@@measurements/DESELECT_MEASUREMENT'
}
