import React from "react";
import { parseISO, format } from "date-fns";
import {Measurement} from "../../model/entities";
import {MeasurementDetails} from "./MeasurementDetails";
import AddIcon from '@material-ui/icons/Add';
import {
    Paper,
    TableCell,
    TableHead,
    Typography,
    Table,
    TableBody,
    TableRow,
    IconButton
} from "@material-ui/core";
import {MouseEvent as RMouseEvent} from 'react';
import history from "../../History";

interface MeasurementsOrSelectedProps {
    measurements: Measurement[];
    selectedMeasurement?: Measurement;
    selectedMeasurementImageData?: Blob;
    onItemSelect: (item: Measurement) => void;
    onItemViewClose: (item: Measurement) => void;
}

interface MeasurementsOrSelectedState {

}

export class MeasurementsOrSelected extends React.Component<MeasurementsOrSelectedProps, MeasurementsOrSelectedState> {
    render(): React.ReactNode {
        const {measurements, selectedMeasurement, selectedMeasurementImageData, onItemSelect, onItemViewClose} = this.props;
        const makeClickHandler = (item: Measurement) => (event: RMouseEvent<HTMLTableRowElement, MouseEvent>) => {
            onItemSelect(item)
        };

        if (selectedMeasurement) {
            return <MeasurementDetails measurement={selectedMeasurement} measureImageData={selectedMeasurementImageData} onCloseSelection={onItemViewClose}/>
        } else {
            return <Paper>
                <Typography variant="h6" id="measuresTableTitle" align="center">
                    Измерения
                </Typography>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Адрес</TableCell>
                            <TableCell align="center">Дата</TableCell>
                            <TableCell align="center">Качество</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {measurements.map(row => (
                            <TableRow key={row.id} onClick={makeClickHandler(row)}>
                                <TableCell component="th" scope="row">
                                    {row.address}
                                </TableCell>
                                <TableCell align="right">{row.measureTime ? format(parseISO(row.measureTime), "dd.MM.yyyy hh:mm:ss") : ""}</TableCell>
                                <TableCell align="right">{row.quality}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <IconButton size="medium" color="primary" style={{
                    bottom: 50,
                    position: 'fixed',
                    right: 50,
                }} onClick={() => history.push("/importMeasurement")}>
                    <AddIcon fontSize="large" />
                </IconButton>
            </Paper>
        }
    }
}
