import * as React from "react";
import {ReactNode} from "react";
import {AgGridReact} from "ag-grid-react";
import {ErrorDataContainer} from "../../../model/ErrorDataContainer";
import {GridOptions} from 'ag-grid-community';
// import GridLoadingOverlay from "./GridLoadingOverlay";
// import {GridNoRowsOverlay} from "./GridNoRowsOverlay";
import * as _ from 'lodash';

export interface EntitiesGridProps extends GridOptions {
    loading: boolean;
    saving: boolean;
    removing: boolean;
    dataProvider?: any[];
    dataId?: any; // to know whether grid should be updated
    totalCount: number;
    listFetchError?: ErrorDataContainer;
    columnDefs: any;
    children?: ReactNode;
    selectable?: boolean;
    pageSizes?: number[];
    sorting?: any[]; // TODO
}

interface EntitiesGridState {
    sorting: any;
    pageSizes: number[];
    pageSize: number;
    currentPage: number;
    data: any[];
    dataId?: any;
    selected?: any[];
    gridApi?: any;
    gridColumnApi?: any;
}

export class EntitiesGrid extends React.Component<EntitiesGridProps, EntitiesGridState> {
    constructor(props: EntitiesGridProps) {
        super(props);

        const pSizes = props.pageSizes ? props.pageSizes : [25, 50, 100];
        this.onGridReady = this.onGridReady.bind(this);
        this.state = {
            sorting: [],
            pageSizes: pSizes,
            pageSize: pSizes[0],
            data: [],
            currentPage: 0
        };
    }

    onGridReady(params: any): void {
        this.setState({
            gridApi: params.api,
            gridColumnApi: params.columnApi
        });
        this.props.loading ? params.api.showLoadingOverlay() : params.api.hideOverlay();
        if (params.api && this.state.data) params.api.setRowData(this.state.data);
        params.api.sizeColumnsToFit();
        window.addEventListener("resize", function() {
            setTimeout(function() {
                params.api.sizeColumnsToFit();
            });
        });
    }

    componentWillReceiveProps(nextProps: Readonly<EntitiesGridProps>, nextContext: any): void {
        if (!_.isEqual(nextProps.dataId, this.state.dataId)) {
            this.setState({
                data: nextProps.rowData ? nextProps.rowData : [],
                dataId: nextProps.dataId
            });
            if (this.state.gridApi) {
                this.state.gridApi.setRowData(_.get(nextProps, 'rowData', []));
                this.state.gridApi.sizeColumnsToFit();
            }
        }
    }

    public render(): React.ReactNode {
        const {children, dataProvider, columnDefs, loading, ...otherProps} = this.props;
        if (loading && this.state.gridApi) {
            this.state.gridApi.showLoadingOverlay();
        } else if (this.state.gridApi) {
            this.state.gridApi.hideOverlay();
        }
        return (
            <div style={{width: "100%", height: "100%"}}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{overflow: "hidden", flexGrow: 1}}>
                        <div className={'ag-theme-material'}
                             style={{boxSizing: "border-box", width: "100%"}}>
                            <AgGridReact
                                onGridReady={this.onGridReady}
                                pagination={true}
                                domLayout='autoHeight'
                                columnDefs = {columnDefs}
/*
                                loadingOverlayComponentFramework = {GridLoadingOverlay}
                                noRowsOverlayComponentFramework = {GridNoRowsOverlay}
*/
                                {...otherProps}
                            >
                                {children}
                            </AgGridReact>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
