import {
    createItemAction, editCancelAction,
    editItemAction, fetchAllListAction,
    fetchKeyedEntityAction,
    removeItemAction,
    saveItemAction,
    updateItemAction
} from "../../../store/models/actions";
import * as React from "react";
import {Button, DialogActions, DialogContent, DialogTitle, Dialog, IconButton, Typography} from "@material-ui/core";
import EntitiesGridWrapper, {EntitiesGridWrapperProps} from "./EntitiesGridWrapper";
import * as _ from 'lodash';
import {AppState} from "../../../store";
import {Dispatch} from "redux";
import {EntitiesModelState} from "../../../store/models/types";
import {connect} from "react-redux";
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {EmptyComponent} from "./EmptyComponent";
import {ReactNode} from "react";
import {PropertyPath} from "lodash";


interface StateProps {
    createEntity?: any;
    editEntity?: any;
}

interface DispatchProps {
    createItem: typeof createItemAction;
    editCancel: typeof editCancelAction;
    fetchAll: typeof fetchAllListAction;
    editItem: typeof editItemAction;
    fetchKeyedEntity: typeof fetchKeyedEntityAction;
    saveItem: typeof saveItemAction;
    updateItem: typeof updateItemAction;
    removeItem: typeof removeItemAction;
}

interface CRUDProps extends EntitiesGridWrapperProps, StateProps, DispatchProps{
    createEnabled: boolean;
    editEnabled: boolean;
    deleteEnabled: boolean;
    withDeleteAlert?: boolean;
    itemAdditionalActions?: (item: any) => ReactNode[],
    createForm?: React.ComponentType;
    editForm?: React.ComponentType;
}

interface CRUDState {
    columnDefs: any[];
    createEntity?: any;
    editEntity?: any;
    deletingEntity?: any;
    openDeleteAlert: boolean;
}

class CRUDEntities extends React.Component<CRUDProps, CRUDState> {

    constructor(props: CRUDProps) {
        super(props);
        this.state = {
            columnDefs: _.cloneDeep(props.columnDefs),
            openDeleteAlert: false
        };
        this.handleStateChange = this.handleStateChange.bind(this);
        this.clearEditState = this.clearEditState.bind(this);
        this.closeDeleteAlert = this.closeDeleteAlert.bind(this);
        this.openDeleteAlert = this.openDeleteAlert.bind(this);
    }

    clearEditState = () => {
      this.setState({...this.state,
          createEntity: undefined,
          editEntity: undefined});
    };

    openDeleteAlert = (entity: any) => {
        this.setState({...this.state,
            openDeleteAlert: true,
            deletingEntity: entity
        });
    };


    closeDeleteAlert = () => {
      this.setState({...this.state,
          openDeleteAlert: false,
          deletingEntity: undefined
      });
    };

    handleStateChange = (entityKey: string) => (upd: any) => {
        this.setState(
            _.assign(this.state, {
                [entityKey]: _.merge(this.state[entityKey], upd)
            })
        );
    };

    getStateValue = (entityKey: string) => (path: PropertyPath) => {
        return _.get(this.state, entityKey) ? _.get(_.get(this.state, entityKey), path) : null;
    };

    public render(): React.ReactNode {
        const {
            dataKey,
            title,
            createForm,
            editForm,
            createEntity,
            editEntity,
            fetchAll,
            saveItem,
            updateItem,
            createItem,
            editCancel,
            editEnabled,
            deleteEnabled,
            fetchKeyedEntity,
            editItem,
            removeItem,
            itemAdditionalActions,
            columnDefs,
            children,
            withDeleteAlert,
            ...otherProps
        } = this.props;
        const stateCreateEntity = this.state.createEntity;
        const stateEditEntity = this.state.editEntity;
        const openDeleteAlert = this.state.openDeleteAlert;
        const CreateFormComponent = createForm ? createForm as React.ComponentType<any> : EmptyComponent as React.ComponentType<any>;
        const EditFormComponent = editForm ? editForm as React.ComponentType<any> : EmptyComponent as React.ComponentType<any>;
        if (!stateCreateEntity && createEntity) {
            this.setState(_.assign(this.state, {createEntity}));
        } else if (!stateEditEntity && editEntity) {
            this.setState(_.assign(this.state, {editEntity}));
        } else if ((stateEditEntity || stateCreateEntity) && !createEntity && !editEntity) {
            this.clearEditState();
        }

        return (
            <div>
                {!!createForm && <Dialog
                    open={!!stateCreateEntity}
                    onClose={() => editCancel(dataKey)}
                    scroll='paper'
                    aria-labelledby="scroll-dialog-title"
                >
                    <DialogTitle id="scroll-dialog-title">Добавить</DialogTitle>
                    <DialogContent>
                        <CreateFormComponent model={stateCreateEntity}
                                             handleState={this.handleStateChange('createEntity')}
                                             getStateValue={this.getStateValue('createEntity')} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => saveItem(dataKey, stateCreateEntity)} color="primary">
                            Сохранить
                        </Button>
                        <Button onClick={() => editCancel(dataKey)} color="secondary">
                            Отмена
                        </Button>
                    </DialogActions>
                </Dialog>}
                {!!editForm && <Dialog
                    open={!stateCreateEntity && !!stateEditEntity}
                    onClose={() => editCancel(dataKey)}
                    scroll='paper'
                    aria-labelledby="scroll-dialog-title"
                >
                    <DialogTitle id="scroll-dialog-title">Изменить</DialogTitle>
                    <DialogContent>
                        <EditFormComponent
                            model={stateEditEntity}
                            handleState={this.handleStateChange('editEntity')}
                            getStateValue={this.getStateValue('editEntity')} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => updateItem(dataKey, stateEditEntity)} color="primary">
                            Сохранить
                        </Button>
                        <Button onClick={() => editCancel(dataKey)} color="secondary">
                            Отмена
                        </Button>
                    </DialogActions>
                </Dialog>}
                {withDeleteAlert && <Dialog
                    open={openDeleteAlert}
                    onClose={this.closeDeleteAlert}
                    scroll='paper'
                    aria-labelledby="scroll-dialog-title"
                >
                    <DialogTitle id="scroll-dialog-title">Предупреждение</DialogTitle>
                    <DialogContent>
                        <Typography>Вы уверены, что хотите удалить запись?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            removeItem(dataKey, this.state.deletingEntity.id);
                            this.closeDeleteAlert()
                        }} color="primary">
                            Да
                        </Button>
                        <Button onClick={this.closeDeleteAlert} color="secondary">
                            Нет
                        </Button>
                    </DialogActions>
                </Dialog>}
                <EntitiesGridWrapper
                    dataKey = {dataKey}
                    title = {title}
                    gridActions = {[createForm && <Button size="small" color="primary" onClick={() => createItem(dataKey)}>
                        <AddIcon /> Создать
                    </Button>,<Button size="small" color="primary" onClick={() => fetchAll(dataKey)}>
                        <RefreshIcon /> Обновить
                    </Button>]}
                    columnDefs = {columnDefs}
                    itemActions = {(item: any) => _.filter(_.concat([editEnabled ? <IconButton
                            color = 'primary'
                            onClick = {() => editItem(dataKey, item)}>
                            <EditIcon />
                        </IconButton> : null,
                        deleteEnabled ? <IconButton
                            color = 'secondary'
                            onClick = {() => withDeleteAlert ? this.openDeleteAlert(item) : removeItem(dataKey, item.id)}>
                            <DeleteIcon />
                        </IconButton> : null,
                    ], itemAdditionalActions ? itemAdditionalActions(item) : []), (e) => !!e)}
                    {...otherProps}
                >
                    {children}
                </EntitiesGridWrapper>
            </div>
        );
    }
}

function mapStateToProps(state: AppState, ownProps: CRUDProps): StateProps {
    const entitiesModel = state.entities[ownProps.dataKey].entities;
    return {
        createEntity: entitiesModel.activeCreateElement,
        editEntity: entitiesModel.activeEditElement
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createItem: (dataKey: keyof EntitiesModelState, entity?: any) => dispatch(createItemAction(dataKey, entity)),
    editCancel: (dataKey: keyof EntitiesModelState) => dispatch(editCancelAction(dataKey)),
    fetchAll: (dataKey: keyof EntitiesModelState) => dispatch(fetchAllListAction(dataKey)),
    editItem: (dataKey: keyof EntitiesModelState, entity: any) => dispatch(editItemAction(dataKey, entity)),
    fetchKeyedEntity: (dataKey: keyof EntitiesModelState, id: any) => dispatch(fetchKeyedEntityAction(dataKey, id)),
    saveItem: (dataKey: keyof EntitiesModelState, entity: any) => dispatch(saveItemAction(dataKey, entity)),
    updateItem: (dataKey: keyof EntitiesModelState, entity: any) => dispatch(updateItemAction(dataKey, entity)),
    removeItem: (dataKey: keyof EntitiesModelState, id: any) => dispatch(removeItemAction(dataKey, id))
});
export default connect<StateProps, DispatchProps, CRUDProps>(mapStateToProps, mapDispatchToProps)(CRUDEntities) as any; // TODO: typed
