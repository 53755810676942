import {LayoutActionTypes} from "./types";
import {Action} from "redux";
import {action} from "typesafe-actions";

export interface LayoutAction extends Action<LayoutActionTypes> {
    payload?: any;
}

export const openDrawer = () => {
    return action(LayoutActionTypes.OPEN_SIDEBAR);
};
export const closeDrawer = () => {
    return action(LayoutActionTypes.CLOSE_SIDEBAR);
};