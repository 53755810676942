import * as React from "react";
import {ReactNode} from "react";
import NavBar from '../components/admin/NavBar';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './layouts.css';
import LiqwizBottomPanel from "../container/public/BottomPanel";

type Props = {
    children?: ReactNode
}

export class PublicLayout extends React.Component<Props> {
    render() {
        return (<div>
            {this.props.children}
            <LiqwizBottomPanel />
        </div>);
    }
}

export class AdminLayout extends React.Component<Props> {
    render() {
        return (
            <div>
                <NavBar >
                    {this.props.children}
                    <ToastContainer
                        autoClose={5000}
                    />
                    <LiqwizBottomPanel />
                </NavBar>
            </div>)
    }
}