import {ICellRendererParams} from 'ag-grid-community';
import * as React from "react";
import {Chip} from "@material-ui/core";
import {get} from 'lodash';
import {Roles} from "../../forms/CreateUser";

export interface RoleCellProps extends ICellRendererParams {
    readonly fieldName: string;
}

export class RoleCellRenderer extends React.Component<RoleCellProps> {
    render(): React.ReactNode {
        return (
            get(this.props, ['data', this.props.fieldName], []).map((role: string) => (
                <Chip
                    color={'primary'}
                    label={Roles[role]}
                />
            ))
        );
    }
}
