import * as React from 'react';
import {ReactNode} from "react";
import Checkbox from '@material-ui/core/Checkbox';
import {ICellRendererParams} from 'ag-grid-community';
import {get} from 'lodash';

export interface SwitchCellProps extends ICellRendererParams {
    readonly fieldName: string;
}

export class SwitchCellRenderer extends React.Component<SwitchCellProps> {
    render(): ReactNode {
        return (<Checkbox
            checked={get(this.props, ['data', this.props.fieldName], false)}
            value={get(this.props, ['data', this.props.fieldName], false).toString()}
        />);
    }
}