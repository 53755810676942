import {ReactNode} from "react";
import {EntitiesGrid, EntitiesGridProps} from "./EntitiesGrid";
import {Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {fetchAllListAction} from "../../../store/models/actions";
import {EntitiesModelState} from "../../../store/models/types";
import * as React from "react";
import {ErrorDataContainer} from "../../../model/ErrorDataContainer";
import {AppState} from "../../../store";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography/Typography";
import * as _ from 'lodash';
import {ActionsCellRenderer} from "./cells/ActionsCellRenderer";

const useStyles = makeStyles({
    root: {
        // paddingTop: theme.spacing.length * 2,
        // paddingBottom: theme.spacing.unit * 2,
        marginBottom: '2em'
    },
    sectionTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    button: {
        margin: 5
    }
});

interface StateProps {
    loading: boolean;
    saving: boolean;
    removing: boolean;
    dataProvider?: any[];
    dataTime?: Date;
    listFetchError?: ErrorDataContainer;
    entitySaveError?: ErrorDataContainer;
}

interface DispatchProps {
    fetchAll: typeof fetchAllListAction;
}


export interface EntitiesGridWrapperProps extends EntitiesGridProps, DispatchProps, StateProps {
    dataKey: keyof EntitiesModelState;
    defaultFilter?: (item: any) => boolean;
    selectable?: boolean;
    title?: string;
    gridActions?: ReactNode[];
    itemActions?: (item: any) => ReactNode[];
    children?: ReactNode;
    sorting?: any[];   // TODO
    referenceKeys?: (keyof EntitiesModelState)[];
}

function EntitiesGridWrapper(props: EntitiesGridWrapperProps) {
    const {
        children,
        dataProvider,
        dataTime,
        defaultFilter,
        title,
        gridActions,
        itemActions,
        loading,
        saving,
        removing,
        pageSizes,
        columnDefs,
        totalCount,
        ...otherProps
    } = props;

    const rowData = defaultFilter ? _.filter(dataProvider, defaultFilter) : dataProvider;
    const dataMark = dataTime ? dataTime.getTime() : 0;
    const tableColumns = (itemActions && columnDefs) ? _.concat(columnDefs, [
        {   field: '',
            cellRendererFramework: ActionsCellRenderer,
            cellRendererParams: {
                actions: itemActions
            }
        }]) : columnDefs;
    const classes = useStyles(props);

    return (
        <Paper className={classes.root}>
            <div className={classes.sectionTitle}>
                {!!title && <Typography component="h3" style={{marginBottom: '15px'}}>
                    {title}
                </Typography>}
                {!_.isEmpty(gridActions) && <span>{gridActions}</span>}
            </div>
            <EntitiesGrid loading={loading}
                          saving={saving}
                          removing={removing}
                          totalCount={rowData ? _.size(dataProvider) : 0}
                          pageSizes={pageSizes}
                          rowData={rowData}
                          dataId={dataMark}
                          columnDefs={tableColumns}
                          {...otherProps}
            >
                {children}
            </EntitiesGrid>
        </Paper>
    );
}

class ConnectedEntitiesGridWrapper extends React.Component<EntitiesGridWrapperProps> {
    constructor(props: EntitiesGridWrapperProps) {
        super(props);
    }

    componentDidMount(): void {
        this.props.fetchAll(this.props.dataKey);
        (this.props.referenceKeys ? this.props.referenceKeys : []).map(key => this.props.fetchAll(key))
    }

    public render(): React.ReactNode {
        return <EntitiesGridWrapper {...this.props}/>
    }
}


function mapStateToProps(state: AppState, ownProps: EntitiesGridWrapperProps): StateProps {
    const entitiesModel = state.entities[ownProps.dataKey].entities;
    return {
        loading: entitiesModel.fetchingList,
        saving: entitiesModel.savingElement,
        removing: entitiesModel.deleting,
        listFetchError: entitiesModel.listFetchError,
        entitySaveError: entitiesModel.saveError,
        dataTime: entitiesModel.elementsFetchedTime,
        dataProvider: entitiesModel.elements
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchAll: (dataKey: keyof EntitiesModelState) => dispatch(fetchAllListAction(dataKey)),
});
export default connect<StateProps, DispatchProps, EntitiesGridProps>(mapStateToProps, mapDispatchToProps)(ConnectedEntitiesGridWrapper) as any;
