import {MeasurementsActionTypes} from "./types";
import {action, PayloadMetaAction} from "typesafe-actions";
import {ErrorDataContainer} from "../../model/ErrorDataContainer";
import {Measurement} from "../../model/entities";

export interface MeasurementsActionPayload {
    id?: any,
    filter?: any,
    selectedMeasurement?: Measurement,
    measurementsList?: Measurement[],
    error?: ErrorDataContainer
}

export type MeasurementAction = PayloadMetaAction<MeasurementsActionTypes, MeasurementsActionPayload, void>

export const fetchMeasurementsListAction = (filter?: any) => {
    return action(MeasurementsActionTypes.REQUEST_MEASUREMENTS, {filter});
};
export const fetchMeasurementsListSuccessAction = (listData: any[]) => {
    return action(MeasurementsActionTypes.MEASUREMENTS_GET_SUCCESS, { measurementsList: listData });
};
export const fetchMeasurementsListErrorAction = (error: ErrorDataContainer) => {
    return action(MeasurementsActionTypes.MEASUREMENTS_GET_SUCCESS, { error });
};
export const setSelectedMeasurement = (measurement?: Measurement) => {
    return action(MeasurementsActionTypes.SELECT_MEASUREMENT, { selectedMeasurement: measurement });
};
export const setSelectedMeasurementImage = (imageData?: Blob) => {
    return action(MeasurementsActionTypes.MEASUREMENT_IMAGE_GET_SUCCESS, { selectedMeasurementImageData: imageData });
};
export const closeMeasurementSelection = (measurement?: Measurement) => {
    return action(MeasurementsActionTypes.DESELECT_MEASUREMENT, { selectedMeasurement: measurement });
};
