import {Reducer} from "redux";
import {EntitiesActionTypes, EntitiesModelState, EntityStateDescription} from "./types";
import {
    UserAccount} from "../../model/entities";
import {EntityAction} from "./actions";
import * as _ from 'lodash';
import {Identifiable} from "../../model/base/Identifiable";

function makeInitialEntityState<T extends Identifiable<any>>(urlBase: string, factory: () => T, mapFun?: (obj: any) => T): EntityStateDescription<T> {
    return {
        urlSpace: urlBase,
        defaultFactory: factory,
        mapFun,
        entities: {
            deleting: false,
            fetchingElement: false,
            fetchingList: false,
            savingElement: false,
            savingList: false
        }
    }
}

export const initialEntitiesState: EntitiesModelState = {
    users: makeInitialEntityState<UserAccount>('/userAccounts', () => new UserAccount())
};

const reducer: Reducer<EntitiesModelState> = (state = initialEntitiesState, action: EntityAction): EntitiesModelState => {
    switch (action.type) {
        case EntitiesActionTypes.FETCH_ALL:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        elements: [],
                        fetchingList: true
                    }
                }
            })};
        case EntitiesActionTypes.FETCH_FILTERED:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        elements: [],
                        fetchingList: true
                    }
                }
            })};
        case EntitiesActionTypes.FETCH_FILTERED_SUCCESS:
            return Object.assign({...state}, {
                [action.meta.dataKey]: {
                    ...state[action.meta.dataKey],
                    entities: {
                        fetchingList: false,
                        elements: action.payload.listData,
                        elementsFetchedTime: new Date()
                    }
                }
            });
        case EntitiesActionTypes.FETCH_FILTERED_FAILURE:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        fetchingList: false,
                        error: action.payload.error
                    }
                }
            })};
        case EntitiesActionTypes.LIST_FETCH_SUCCESS:
            return {
                ...state,
                [action.meta.dataKey]: {
                    ...state[action.meta.dataKey],
                    entities: {
                        ...state[action.meta.dataKey].entities,
                        fetchingList: false,
                        elements: action.payload.listData,
                        elementsFetchedTime: new Date()
                    }
                }
            };
        case EntitiesActionTypes.LIST_FETCH_FAILED:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        fetchingList: false,
                        error: action.payload.error
                    }
                }
            })};
        case EntitiesActionTypes.FETCH_BY_ID:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        fetchingElement: true
                    }
                }
            })};
        case EntitiesActionTypes.SINGLE_FETCH_SUCCESS:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        fetchingElement: false,
                        elements: [action.payload.entity]
                    }
                }
            })};
        case EntitiesActionTypes.SINGLE_FETCH_FAILED:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        fetchingElement: false
                    }
                }
            })};
        case EntitiesActionTypes.CREATE_ITEM:
            const activeItem = action.payload.entity ? _.merge(state[action.meta.dataKey].defaultFactory(), action.payload.entity) : state[action.meta.dataKey].defaultFactory();
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        activeCreateElement: activeItem,
                        activeEditElement: undefined
                    }
                }
            })};
        case EntitiesActionTypes.EDIT_ITEM:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        activeCreateElement: undefined,
                        activeEditElement: action.payload.entity
                    }
                }
            })};
        case EntitiesActionTypes.EDIT_CANCEL:
            const dropped = _.omit(_.cloneDeep(state[action.meta.dataKey]).entities, ['activeCreateElement', 'activeEditElement']);
            return {..._.assign(state, {
                [action.meta.dataKey]: {
                    ...state[action.meta.dataKey],
                    entities: dropped
                }
            })};
        case EntitiesActionTypes.SAVE_ITEM:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        savingElement: true
                    }
                }
            })};
        case EntitiesActionTypes.UPDATE_ITEM:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        savingElement: true
                    }
                }
            })};
        case EntitiesActionTypes.SAVE_SUCCESS:
            const droppedEnt = _.omit(_.cloneDeep(state[action.meta.dataKey]).entities, ['activeCreateElement', 'activeEditElement']);
            return {..._.assign(state, {
                [action.meta.dataKey]: {
                    ...state[action.meta.dataKey],
                    entities: {
                        ...droppedEnt,
                        savingElement: false
                    }
                }
            })};
        case EntitiesActionTypes.SAVE_FAILED:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        savingElement: false,
                        saveError: action.payload
                    }
                }
            })};
        case EntitiesActionTypes.REMOVE:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        deleting: true
                    }
                }
            })};
        case EntitiesActionTypes.REMOVE_SUCCESS:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        deleting: false
                    }
                }
            })};
        case EntitiesActionTypes.REMOVE_FAILED:
            return {..._.merge(state, {
                [action.meta.dataKey]: {
                    entities: {
                        deleting: false,
                        saveError: action.payload
                    }
                }
            })};
        default: {
            return state
        }
    }
};

export {reducer as crudReducer};