import * as React from "react";
import {AdminLayout} from "../../layout";
import CRUDEntities from "../../components/admin/commons/CRUDEntities";
import CreateUserForm from "../../components/admin/forms/CreateUser";
import {SwitchCellRenderer} from "../../components/admin/commons/cells/switchCellRenderer";
import {RoleCellRenderer} from "../../components/admin/commons/cells/RoleCellRenderer";
import EditUserForm from "../../components/admin/forms/EditUserForm";

export class UsersManagement extends React.Component {
    userColumns = [
        {

        },
        {
            headerName: 'Логин',
            field: 'loginInfo.login',
            filter: 'text',
            cellClass: 'cell-wrap-text',
        },
        {
            headerName: 'Почта',
            field: 'email',
            filter: 'text',
            cellClass: 'cell-wrap-text'
        },
        {
            headerName: 'Имя',
            field: 'name',
            filter: 'text',
            cellClass: 'cell-wra-text'
        },
        {
            headerName: 'Дата регистрации',
            field: 'registerDate',
            filter: 'date',
        },
        {
            headerName: 'Неактивен',
            field: 'disabled',
            cellRendererFramework: SwitchCellRenderer,
            cellRendererParams: {
                fieldName: 'disabled'
            }
        },
        {
            headerName: 'Роль',
            filed: 'roles',
            cellRendererFramework: RoleCellRenderer,
            cellRendererParams: {
                fieldName: 'roles'
            }
        }
    ];

    render(): React.ReactNode {
        return (
            <AdminLayout>
                <CRUDEntities
                    dataKey={'users'}
                    title={'Пользователи'}
                    createForm={CreateUserForm}
                    editForm={EditUserForm}
                    columnDefs={this.userColumns}
                    editEnabled={true}
                    deleteEnabled={true}
                    withDeleteAlert={true}
                />
            </AdminLayout>
        );
    }
}