import * as React from "react";
import {PublicLayout} from '../../layout';
import './login.css';
// import logoPath from '../../assets/images/lw_logo.png';
import LoginComponent from "../../components/public/LoginComponent";

export class LoginScreen extends React.Component {
    render() {

        return (
            <PublicLayout>
                <div className="login container">
                    <div className="login-content">
                        <div className="login-branding">
                            <img className="logo-icon" src={process.env.PUBLIC_URL + "/assets/images/voop_logo.jpg"} alt="Logo" />
                        </div>
                        <div className="login-outer-box">
                            <div className="login-inner-box" id="login-view">
                                <LoginComponent />
                            </div>
                        </div>
                    </div>
                </div>
            </PublicLayout>
        );
    }
}
