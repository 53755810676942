import {MeasurementsModelState, MeasurementsActionTypes} from "./types";
import {MeasurementAction} from "./actions";

export const initialMeasurementsState: MeasurementsModelState = {
    measurementsList: []
};

const reducer = (state: MeasurementsModelState = initialMeasurementsState, action: MeasurementAction): MeasurementsModelState => {
    switch (action.type) {
        case MeasurementsActionTypes.REQUEST_MEASUREMENTS: {
            return state
        }
        case MeasurementsActionTypes.SELECT_MEASUREMENT: {
            return {
                ...state,
                ...action.payload
            }
        }
        case MeasurementsActionTypes.DESELECT_MEASUREMENT: {
            return {
                ...state,
                selectedMeasurement: undefined,
                selectedMeasurementImageData: undefined
            }
        }
        case MeasurementsActionTypes.MEASUREMENT_IMAGE_GET_SUCCESS: {
            return {
                ...state,
                ...action.payload
            }
        }
        case MeasurementsActionTypes.MEASUREMENTS_GET_SUCCESS: {
            return {
                ...state,
                ...action.payload
            }
        }
        default: {
            return state
        }
    }
};

export { reducer as measurementsReducer };
