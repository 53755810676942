import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {Metadata} from "./Metadata";
import history from "../History";
import {Routes} from "../route/RouteElem";
import {toast} from "react-toastify";
import {appSecurityService} from "./securityService";

class AxiosHelper {
    protected axiosWorker: AxiosInstance;

    constructor() {
        this.axiosWorker = axios.create();
        this.axiosWorker.defaults.baseURL = process.env.PUBLIC_URL + '/api';
        this.axiosWorker.interceptors.response.use((response) => {
            return response;
        }, function (error) {
            // Do something with response error
            if (error.response.status === 401) {
                appSecurityService.invalidateToken();
                history.push(Routes.loginRoute.path);
            } else {
                const msg = error.response.status + ": Запрос завершился с ошибкой" + (!!error.response.data ? ":\n" + error.response.data : '');
                toast(msg,{type: toast.TYPE.ERROR})
            }
            const msg = !!error.response.data ? error.response.data : error.response.status + ": Запрос завершился с ошибкой";
            return Promise.reject(msg);
        });

    }


    private static securityHeaders(headers: any, user: string, token: string): any {
        return {
            ...headers,
            [Metadata.HttpHeaders.XAuthUser]: user,
            [Metadata.HttpHeaders.XAuthToken]: token
        }
    }

    private static withSecurityHeaders(config?: AxiosRequestConfig): AxiosRequestConfig | undefined {
        const credentials = appSecurityService.getCredentials();
        const cfg = !!config ? config! : {};
        return !!credentials ? {
            ...config,
            headers: AxiosHelper.securityHeaders(!!cfg.headers ? cfg.headers : {}, credentials.user, credentials.token)
        } : config
    }

    public getData<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.axiosWorker
            .get<T>(url, AxiosHelper.withSecurityHeaders(config))
            .catch((error) => {
                return Promise.reject(error.response);
            });
    }
    public postData<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.axiosWorker
            .post<T>(url, data, AxiosHelper.withSecurityHeaders(config))
            .catch((error) => {
                return Promise.reject(error.response);
            });
    }
    public putData<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.axiosWorker
            .put<T>(url, data, AxiosHelper.withSecurityHeaders(config))
            .catch((error) => {
                if (error.response && error.response.code == 403) {

                }
                return Promise.reject(error.response);
            });
    }
    public deleteData(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.axiosWorker
            .delete(url, AxiosHelper.withSecurityHeaders(config))
            .catch((error) => {
                return Promise.reject(error.response);
            });
    }}

const axiosClient = new AxiosHelper();
export default axiosClient;
