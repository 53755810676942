import {ErrorDataContainer} from "../../model/ErrorDataContainer";
import {
    UserAccount
} from "../../model/entities";
import {Identifiable} from "../../model/base/Identifiable";

export interface EntityState<T> {
    readonly fetchingElement: boolean;
    readonly fetchingList: boolean;
    readonly savingElement: boolean;
    readonly savingList: boolean;
    readonly deleting: boolean;
    readonly elements?: T[];
    readonly elementsFetchedTime?: Date;
    readonly selectedElements?: T[];
    readonly activeCreateElement?: T;
    readonly activeEditElement?: T;
    readonly listFetchError?: ErrorDataContainer;
    readonly entityFetchError?: ErrorDataContainer;
    readonly saveError?: ErrorDataContainer;
}

export interface EntityStateDescription<T extends Identifiable<any>> {
    readonly entities: EntityState<T>;
    readonly defaultFactory: () => T;
    readonly mapFun?: (obj: any) => T;
    readonly urlSpace: string;
}

export interface EntitiesModelState {
    readonly users: EntityStateDescription<UserAccount>;
}

export enum EntitiesActionTypes {
    FETCH_ALL = '@@entities/FETCH_ALL',
    FETCH_FILTERED = '@@entities/FETCH_FILTERED',
    FETCH_FILTERED_SUCCESS = '@@entities/FETCH_FILTERED_SUCCESS',
    FETCH_FILTERED_FAILURE = '@@entities/FETCH_FILTERED_FAILURE',
    LIST_FETCH_SUCCESS = '@@entities/LIST_FETCH_SUCCESS',
    LIST_FETCH_FAILED = '@@entities/LIST_FETCH_FAILED',
    FETCH_BY_ID = '@@entities/FETCH_BY_ID',
    SINGLE_FETCH_SUCCESS = '@@entities/SINGLE_FETCH_SUCCESS',
    SINGLE_FETCH_FAILED = '@@entities/SINGLE_FETCH_FAILED',
    CREATE_ITEM = '@@entities/CREATE_ITEM',
    EDIT_ITEM = '@@entities/EDIT_ITEM',
    EDIT_CANCEL = '@@entities/EDIT_CANCEL',
    SAVE_ITEM = '@@entities/SAVE_ITEM',
    UPDATE_ITEM = '@@entities/UPDATE_ITEM',
    SAVE_SUCCESS = '@@entities/SAVE_SUCCESS',
    SAVE_FAILED = '@@entities/SAVE_FAILED',
    REMOVE = '@@entities/DELETE',
    REMOVE_SUCCESS = '@@entities/DELETE_SUCCESS',
    REMOVE_FAILED = '@@entities/DELETE_FAILED'
}
