// import {messages_en} from "../i18n/messages_en";
// import {messages_ru} from "../i18n/messages_ru";
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

const i18nInstance = i18next
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'ru',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // resources: {
        //     en: {
        //         translation: messages_en
        //     },
        //     ru: {
        //         translation: messages_ru
        //     }
        // },
        // special options for react-i18next
        // learn more: https://react.i18next.com/components/i18next-instance
        react: {
            wait: true
        }
    });


export default i18nInstance;