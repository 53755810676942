import {LoginActionTypes} from "./types";
import {Action} from "redux";
import {action} from "typesafe-actions";
import {UserAuthToken} from "../../model/entities";

export interface SignInAction extends Action<LoginActionTypes> {
    payload?: any;
}

export const authUserAction = (login: string, password: string) => {
    return action(
        LoginActionTypes.REQUEST_TOKEN,
        {login, password, history: History}
    );
};
export const userAuthSuccess = (userAuthToken: UserAuthToken) => {
    return action(LoginActionTypes.LOGIN_SUCCESS, userAuthToken);
};
export const userAuthError = (error: any) => {
    return action(LoginActionTypes.LOGIN_ERROR, error);
};
export const userLogoutAction = () => {
    return action(LoginActionTypes.LOGOUT);
};