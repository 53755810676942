import {UserAccount} from "../../model/entities";

export interface ProfileState {
    user?: UserAccount
    roles?: string[]
}

export const enum ProfileActionTypes {
    REQUEST_PROFILE = '@@profile/REQUEST_PROFILE',
    PROFILE_SUCCESS = '@@profile/PROFILE_REQUEST_SUCCESS',
    PROFILE_FAILURE = '@@profile/PROFILE_REQUEST_FAILURE'
}
