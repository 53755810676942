import {all, apply, fork, put, takeEvery} from 'redux-saga/effects'
import {ProfileActionTypes} from './types'
import {
    requestProfile,
    requestProfileError,
    requestProfileSuccess
} from './actions'
import axiosClient from "../../utils/axiosClient";


function* handleGetProfileRequest(action: ReturnType<typeof requestProfile>) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res = yield apply(axiosClient,
            axiosClient.getData,
            ['/me']);

        if (res.error) {
            yield put(requestProfileError(res.error))
        } else {
            yield put(requestProfileSuccess(res.data));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(requestProfileError({
                errorMessage: err.stack!
            }))
        } else {
            yield put(requestProfileError({
                errorMessage: 'An unknown error occured.'
            }))
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleSignInRequest()` saga above.
function* watchProfileRequests() {
    yield takeEvery(ProfileActionTypes.REQUEST_PROFILE, handleGetProfileRequest)
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* profileSaga() {
    yield all([watchProfileRequests].map(fork))
}

export default profileSaga
