import React, {ComponentClass, ReactElement} from "react";
import ImportMeasurement from "../container/private/ImportMeasurement";
import {Metadata} from "../utils/Metadata";
import Home from "../container/private/Home";
import MapIcon from '@material-ui/icons/Map';
import PortraitIcon from '@material-ui/icons/Portrait';
import * as _ from 'lodash';
import {UsersManagement} from "../container/private/UsersManagement";
import {LoginScreen} from "../container/public/LoginScreen";
import {ConnectedComponent} from "react-redux";

export class RouteElem {
    container: ComponentClass | ConnectedComponent<any, any>;
    path: string;
    label: string;
    exact?: boolean;
    group?: string;
    icon?: () => ReactElement<any>;

    constructor(container: ComponentClass | ConnectedComponent<any, any>, path: string, label: string, exact?: boolean, group?: string, icon?: () => ReactElement<any>) {
        this.container = container;
        this.path = path;
        this.label = label;
        this.exact = exact;
        this.group = group;
        this.icon = icon;
    }
}

export class PrivateRouteElem extends RouteElem {
    roles?: string[];

    constructor(container: ComponentClass | ConnectedComponent<any, any>, path: string, label: string, exact: boolean, group?: string, roles?: string[], icon?: () => ReactElement<any>) {
        super(container, path, label, exact, group, icon);
        this.roles = roles;
    }
}

export class Routes{
    static readonly loginRoute = new RouteElem(LoginScreen, "/login", "Аутентификация", true);
    static readonly publicRoutes: RouteElem[] = [];

    static readonly securedRoutes: RouteElem[] = [
        new PrivateRouteElem(Home, "/", "Карта измерений", true, Metadata.MenuGroups.sideMenuGroup, [Metadata.Roles.admin, Metadata.Roles.operator], () => <MapIcon />),
        new PrivateRouteElem(ImportMeasurement, "/importMeasurement", "Импорт данных измерения", true, undefined, [Metadata.Roles.admin, Metadata.Roles.operator]),
        new PrivateRouteElem(UsersManagement, '/userAccounts', 'Пользователи', true, Metadata.MenuGroups.sideMenuGroup, [Metadata.Roles.admin], () => <PortraitIcon />),
    ];

    static measurementInfo(measurementId: number) {
        return 'measurements/'+measurementId;
    }

    public static allRoutes() {
        return _.concat(Routes.publicRoutes, Routes.securedRoutes, [Routes.loginRoute])
    }

    public static menuGroupRoutes(menuGroup: string, userRoles: string[]) {
        return _.filter(this.allRoutes(), (route: RouteElem) => {return route.group == menuGroup &&
            (_.isEmpty(userRoles) ||
                !(route instanceof PrivateRouteElem) ||
                (!route.roles) ||
                !!_.find(route.roles, (role) => _.includes(userRoles, role)))
        });
    }
}
