import {format, parseISO} from "date-fns";
import {Measurement} from "../../model/entities";
import React from "react";
import {LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer} from 'recharts';
import {
    Card,
    CardHeader,
    IconButton,
    CardContent,
    Table,
    TableBody, TableCell, TableRow, Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import * as _ from 'lodash';


export interface MeasurementDetailsProps {
    measurement: Measurement;
    measureImageData?: Blob;
    onCloseSelection: (measurement: Measurement) => void;
}


export class MeasurementDetails extends React.Component<MeasurementDetailsProps> {
    render(): React.ReactNode {
        const {measurement, measureImageData, onCloseSelection} = this.props;
        let chartData = null;
        if (measurement.measuredData && !_.isEmpty(measurement.measuredData) && measurement.measuredData[0].data) {
            chartData = _.filter(JSON.parse(measurement.measuredData[0].data), i => i.x > 0);
        }
        return(
            <Card>
                <CardHeader action={
                    <IconButton aria-label="settings" onClick={() => {}}>
                        <CloseIcon onClick={(event) => onCloseSelection(measurement)}/>
                    </IconButton>
                } title={"Измерение " + measurement.id} />
                <CardContent>
                {!!measureImageData &&
                    <div style={{margin: 10}}>
                        <Typography variant="h5" style={{marginBottom: 10}}>Изображение</Typography>
                        <img src={URL.createObjectURL(measureImageData)} style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '100%',
                        }} alt="img"/>
                    </div>
                }
                {!!chartData &&
                    <React.Fragment>
                        <div style={{margin: 10}}>
                            <Typography variant="h5" style={{marginBottom: 10}}>Спектр</Typography>
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart data={chartData}>
                                    <CartesianGrid />
                                    <Line type="monotone" dataKey="y" stroke="#8884d8" />
                                    <XAxis dataKey="x" type="number" allowDecimals={false} tickCount={8} domain={[350, 875]}/>
                                    <YAxis />
                                    <Tooltip />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </React.Fragment>
                }
                    <React.Fragment>
                        <div style={{margin: 10}}>
                            <Typography variant="h5" style={{marginBottom: 10}}>Данные измерения</Typography>
                            <Table>
                                <TableBody>
                                    <TableRow key="measurement_type_info_row">
                                        <TableCell component="th" scope="row" align="left">Тип измерения</TableCell>
                                        <TableCell>{measurement.measureType === 'UV' ? "Ультра-фиолет" : "Простое измерение"}</TableCell>
                                    </TableRow>
                                    <TableRow key="measurement_address_info_row">
                                        <TableCell component="th" scope="row" align="left">Адрес</TableCell>
                                        <TableCell>{measurement.address}</TableCell>
                                    </TableRow>
                                    <TableRow key="measurement_fio_row">
                                        <TableCell component="th" scope="row" align="left">ФИО</TableCell>
                                        <TableCell>{measurement.fio}</TableCell>
                                    </TableRow>
                                    <TableRow key="measurement_device_row">
                                        <TableCell component="th" scope="row" align="left">ИД Прибора</TableCell>
                                        <TableCell>{measurement.deviceId}</TableCell>
                                    </TableRow>
                                    <TableRow key="measurement_chip_row">
                                        <TableCell component="th" scope="row" align="left">Версия Чипа</TableCell>
                                        <TableCell>{measurement.chipVersion}</TableCell>
                                    </TableRow>
                                    <TableRow key="measurement_userid_row">
                                        <TableCell component="th" scope="row" align="left">ИД пользователя</TableCell>
                                        <TableCell>{measurement.userId}</TableCell>
                                    </TableRow>
                                    <TableRow key="measurement_time_row">
                                        <TableCell component="th" scope="row" align="left">Дата измерения</TableCell>
                                        <TableCell>{ measurement.measureTime ? format(parseISO(measurement.measureTime), "dd.MM.yyyy hh:mm:ss") : "" }</TableCell>
                                    </TableRow>
                                    <TableRow key="measurement_quality_row">
                                        <TableCell component="th" scope="row" align="left">Качество</TableCell>
                                        <TableCell>В отладочном режиме отклонения не детектированы</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </React.Fragment>
                </CardContent>
            </Card>
        );
    }
}
