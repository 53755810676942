import {LayoutActionTypes, LayoutState} from "./types";
import {LayoutAction} from "./actions";

const initialState: LayoutState = {
    sideBarOpened: false
};
const reducer = (state = initialState, action: LayoutAction): LayoutState => {
    switch (action.type) {
        case LayoutActionTypes.OPEN_SIDEBAR: {
            return { ...state, sideBarOpened: true }
        }
        case LayoutActionTypes.CLOSE_SIDEBAR: {
            return { ...state, sideBarOpened: false }
        }
        case LayoutActionTypes.TOGGLE_SIDEBAR: {
            return state.sideBarOpened ? { ...state, sideBarOpened: false } : { ...state, sideBarOpened: true }
        }
        default: {
            return state
        }
    }
};

export { reducer as layoutReducer };