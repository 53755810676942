import {Redirect, Route, RouteProps} from "react-router-dom";
import * as React from "react";
import {Routes} from "./RouteElem";

export interface SecuredRouteProps extends RouteProps {
    isUserAuthenticated: boolean
}

export class SecuredRoute extends React.Component<SecuredRouteProps> {
    public render() {
        const { component: Component, isUserAuthenticated, path, ...rest } = this.props;
        return <Route {...rest} path={path}  render={(props) => (
            !!Component ?
                isUserAuthenticated ? <Component {...props} />
                    : <Redirect push to={Routes.loginRoute.path}/> : null
        )} />;
    }
}
