import {ErrorDataContainer} from "../../model/ErrorDataContainer";
import {UserAuthToken} from "../../model/entities";

export interface UserAccountState {
    readonly signingIn: boolean
    readonly loginError?: ErrorDataContainer
    readonly token?: UserAuthToken;
}

export enum LoginActionTypes {
    REQUEST_TOKEN = '@@login/REQUEST_TOKEN',
    LOGIN_SUCCESS = '@@login/LOGIN_SUCCESS',
    LOGIN_ERROR = '@@login/LOGIN_ERROR',
    LOGOUT = '@@login/LOGOUT'
}
