import {LayoutState} from "./layout/types";
import {layoutReducer} from "./layout/reducer";
import {Action, AnyAction, combineReducers, Dispatch} from "redux";
import {all, fork} from "redux-saga/effects";
import {crudReducer} from "./models/reducer";
import {EntitiesModelState} from "./models/types";
import entitiesSaga from "./models/sagas";
import {signInReducer} from "./security/reducer";
import signInSaga from "./security/sagas";
import {UserAccountState} from "./security/types";
import {ProfileState} from "./profile/types";
import profileSaga from "./profile/sagas";
import {profileReducer} from "./profile/reducer";
import {MeasurementsModelState} from "./measurements/types";
import measurementsSaga from "./measurements/sagas";
import {measurementsReducer} from "./measurements/reducer";
import {connectRouter, RouterState} from "connected-react-router";
import { History } from 'history'


export interface AppState {
    layout: LayoutState;
    entities: EntitiesModelState;
    measurements: MeasurementsModelState;
    auth?: UserAccountState;
    profile?: ProfileState;
    router: RouterState;
}

export class ErrorContainer {
    public key: string;
    public messages?: string[];
}

export class SubmissionErrorContainer {
    public errorMessage: string;
    public fieldErrors?: ErrorContainer[];
    public formErrors: ErrorContainer[];
}

export interface ConnectedReduxProps<A extends Action = AnyAction> {
    dispatch: Dispatch<A>
}
export function* rootSaga() {
    yield all([fork(entitiesSaga), fork(measurementsSaga), fork(profileSaga), fork(signInSaga)])
}


const rootReducer = (history: History) => combineReducers<AppState>({
    layout: layoutReducer,
    entities: crudReducer,
    measurements: measurementsReducer,
    profile: profileReducer,
    auth: signInReducer,
    router: connectRouter(history)
});

export default rootReducer;
