export interface Identifiable<T> {
    id?: T
    isPersisted(): boolean
}

export class StringIdentifiable implements Identifiable<string> {
    public id: string;

    isPersisted(): boolean {
        return !!this.id;
    }
}

export class LongKeyIdentifiable implements Identifiable<number> {
    constructor(id?: number) {
        this.id = id ? id : 0;
    }

    public id: number = 0;

    isPersisted(): boolean {
        return this.id > 0;
    }
}
