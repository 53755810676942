import {all, apply, fork, put, select, takeEvery} from 'redux-saga/effects'
import {EntitiesActionTypes, EntitiesModelState} from './types'
import {
    fetchAllListAction,
    fetchFilteredListAction, fetchFilteredListFailureAction, fetchFilteredListSuccessAction,
    fetchKeyedEntityAction,
    fetchKeyedEntityErrorAction,
    fetchKeyedEntitySuccessAction,
    listFetchErrorAction,
    listFetchSuccessAction,
    removeItemAction,
    saveItemAction,
    saveItemErrorAction,
    saveItemSuccessAction,
    updateItemAction
} from './actions'
import axiosClient from "../../utils/axiosClient";
import {AppState} from "../index";
import * as _ from 'lodash';

function getModelEntity(key:  keyof EntitiesModelState) {
    return (state: AppState) => state.entities[key];
}

function* handleDataFetchRequest(action: ReturnType<typeof fetchAllListAction>) {
    try {
        const model = yield select(getModelEntity(action.meta.dataKey));

        // To call async functions, use redux-saga's `call()`.
        const res = yield axiosClient.getData(model.urlSpace);

        if (res.error) {
            yield put(listFetchErrorAction(action.meta.dataKey, res.error))
        } else {
            yield put(listFetchSuccessAction(action.meta.dataKey, model.mapFun ? _.map(_.filter(res.data, (v) => !!v), model.mapFun) : res.data));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(listFetchErrorAction(action.meta.dataKey, {
                errorMessage: err.stack!
            }))
        } else {
            yield put(listFetchErrorAction(action.meta.dataKey, {
                errorMessage: 'An unknown error occurred.'
            }))
        }
    }
}

function* handleItemSaveRequest(action: ReturnType<typeof saveItemAction>) {
    try {
        const model = yield select(getModelEntity(action.meta.dataKey));

        // To call async functions, use redux-saga's `call()`.
        const res = yield apply(axiosClient, axiosClient.postData,[model.urlSpace, action.payload.entity]);

        if (res.error) {
            yield put(saveItemErrorAction(action.meta.dataKey, res.error))
        } else {
            yield put(saveItemSuccessAction(action.meta.dataKey, res.data));
            yield put(fetchAllListAction(action.meta.dataKey))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(saveItemErrorAction(action.meta.dataKey, {
                errorMessage: err.stack!
            }))
        } else {
            yield put(saveItemErrorAction(action.meta.dataKey, {
                errorMessage: 'An unknown error occurred.'
            }))
        }
    }
}

function* handleItemUpdateRequest(action: ReturnType<typeof updateItemAction>) {
    try {
        const model = yield select(getModelEntity(action.meta.dataKey));

        const urlToPut = model.urlSpace + (!!action.payload.entity.id ? "/"+action.payload.entity.id : "");
        // To call async functions, use redux-saga's `call()`.
        const res = yield apply(axiosClient, axiosClient.putData,[urlToPut, action.payload.entity]);

        if (res.error) {
            yield put(saveItemErrorAction(action.meta.dataKey, res.error))
        } else {
            yield put(saveItemSuccessAction(action.meta.dataKey, res.data));
            yield put(fetchAllListAction(action.meta.dataKey))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(saveItemErrorAction(action.meta.dataKey, {
                errorMessage: err.stack!
            }))
        } else {
            yield put(saveItemErrorAction(action.meta.dataKey, {
                errorMessage: 'An unknown error occurred.'
            }))
        }
    }
}

function* handleItemRemoveRequest(action: ReturnType<typeof removeItemAction>) {
    try {
        const model = yield select(getModelEntity(action.meta.dataKey));

        const urlToRemove = model.urlSpace + (!!action.payload.id ? "/"+action.payload.id : "");
        // To call async functions, use redux-saga's `call()`.
        const res = yield apply(axiosClient, axiosClient.deleteData,[urlToRemove]);

        if (res.error) {
            yield put(saveItemErrorAction(action.meta.dataKey, res.error))
        } else {
            yield put(fetchAllListAction(action.meta.dataKey))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(saveItemErrorAction(action.meta.dataKey, {
                errorMessage: err.stack!
            }))
        } else {
            yield put(saveItemErrorAction(action.meta.dataKey, {
                errorMessage: 'An unknown error occurred.'
            }))
        }
    }
}

function* handleFetchSingleItemRequest(action: ReturnType<typeof fetchKeyedEntityAction>) {
    try {
        const model = yield select(getModelEntity(action.meta.dataKey));

        const urlToFetch = model.urlSpace + (!!action.payload.id ? `/${action.payload.id}` : '');

        const res = yield apply(axiosClient, axiosClient.getData,[urlToFetch]);

        if (res.error) {
            yield put(fetchKeyedEntityErrorAction(action.meta.dataKey, res.error))
        } else {
            yield put(
                fetchKeyedEntitySuccessAction(
                    action.meta.dataKey,
                model.mapFun ? _.map(_.filter(res.data, (v) => !!v), model.mapFun) : res.data)
            )
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchKeyedEntityErrorAction(action.meta.dataKey, {errorMessage: err.stack!}))
        } else {
            yield put(fetchKeyedEntityErrorAction(action.meta.dataKey, {errorMessage: 'An unknown error occurred.'}))
        }
    }
}

function* handleFetchFilteredRequest(action: ReturnType<typeof fetchFilteredListAction>) {
    try {
        const model = yield select(getModelEntity(action.meta.dataKey));

        const res = yield apply(axiosClient, axiosClient.getData, [model.urlSpace, {params: action.payload.filter}]);

        if (res.error) {
            yield put(fetchFilteredListFailureAction(action.meta.dataKey, res.error))
        } else {
            yield put(
                fetchFilteredListSuccessAction(
                    action.meta.dataKey,
                    model.mapFun ? _.map(_.filter(res.data, (v) => !!v), model.mapFun) : res.data
                )
            )
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchFilteredListFailureAction(action.meta.dataKey, {errorMessage: err.stack!}))
        } else {
            yield put(fetchFilteredListFailureAction(action.meta.dataKey, {errorMessage: 'An unknown error occurred.'}))
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleSignInRequest()` saga above.
function* watchFetchRequests() {
    yield takeEvery(EntitiesActionTypes.FETCH_ALL, handleDataFetchRequest)
}
function* watchSaveItemRequests() {
    yield takeEvery(EntitiesActionTypes.SAVE_ITEM, handleItemSaveRequest)
}
function* watchUpdateItemRequests() {
    yield takeEvery(EntitiesActionTypes.UPDATE_ITEM, handleItemUpdateRequest)
}
function* watchRemoveItemRequests() {
    yield takeEvery(EntitiesActionTypes.REMOVE, handleItemRemoveRequest)
}
function* watchFetchSingleItem() {
    yield takeEvery(EntitiesActionTypes.FETCH_BY_ID, handleFetchSingleItemRequest)
}
function* watchFetchFilteredList() {
    yield takeEvery(EntitiesActionTypes.FETCH_FILTERED, handleFetchFilteredRequest)
}
// We can also use `fork()` here to split our saga into multiple watchers.
function* entitiesSaga() {
    yield all([
        watchFetchRequests,
        watchSaveItemRequests,
        watchUpdateItemRequests,
        watchRemoveItemRequests,
        watchFetchSingleItem,
        watchFetchFilteredList
    ].map(fork))
}


export default entitiesSaga;
