import {
    createStyles,
    FormControl,
    FormControlLabel,
    Input,
    InputLabel, MenuItem, Select, Switch} from "@material-ui/core";
import {UserAccount} from "../../../model/entities";
import * as React from "react";
import {get} from 'lodash';
import {WithStyles, withStyles} from "@material-ui/styles";

const styles = createStyles({
    formControl: {
        width: "500px",
        marginTop: "15px"
    },
    formControlLabel: {
        marginTop: "15px"
    },
    selectEmpty: {
        marginTop: 10
    },
});

export const Roles = {
    'ADMIN': 'Админ',
    'USER': 'Пользователь'
};

export interface CreateUserProps {
    model: UserAccount;
    handleState: (current: Partial<UserAccount>) => void;
}

export interface CreateUserState {
    handleState: (current: Partial<UserAccount>) => void;
    roleSelector: string;
}

class CreateUserForm extends React.Component<CreateUserProps & WithStyles<typeof styles>, CreateUserState> {
    constructor(props: any, context: any) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
        this.handleNumericChange = this.handleNumericChange.bind(this);
        this.handleBooleanChange = this.handleBooleanChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handlePasswordMain = this.handlePasswordMain.bind(this);
        this.handlePasswordConfirm = this.handlePasswordConfirm.bind(this);

        this.state = {
            ...props.model,
            handleState: props.handleState,
            roleSelector: get(props,['model', 'roles', '0'], '')
        }
    }

    handleChange = (name: keyof UserAccount|'login') => (event: React.ChangeEvent<any>) => {
        this.state.handleState({
            [name]: event.target.value
        });
    };

    handleNumericChange = (name: keyof UserAccount) => (event: React.ChangeEvent<any>) => {
        this.state.handleState({
            [name]: Number(event.target.value)
        });
    };

    handleBooleanChange = (name: keyof UserAccount) => (event: React.ChangeEvent<HTMLInputElement>) => {
        this.state.handleState({
            [name]: event.target.checked
        });
    };

    handleRoleSelectorChange = (event: React.ChangeEvent<any>) => {
        const value = event.target.value;
        this.setState({
            roleSelector: value,
        });
        this.state.handleState({
            roles: [value]
        });
    };

    handleLogin = (event: React.ChangeEvent<any>) => {
        this.state.handleState({
            'loginInfo': {
                login: event.target.value
            }
        })
    };

    handlePasswordMain = (event: React.ChangeEvent<any>) => {
        this.state.handleState({
            'password': {
                main: event.target.value
            }
        })
    };

    handlePasswordConfirm = (event: React.ChangeEvent<any>) => {
        this.state.handleState({
            'password': {
                confirm: event.target.value
            }
        })
    };

    render() {
        const {model, classes} = this.props;

        return (
          <div>
              <form id='hoc-form'>
                  {model && <FormControl component={'fieldset'}>
                      <FormControl className={classes.formControl}>
                          <InputLabel htmlFor='login'>Логин</InputLabel>
                          <Input id='login' value={get(model, ['loginInfo', 'login'], '')} onChange={this.handleLogin}/>
                      </FormControl>
                      <FormControl className={classes.formControl}>
                          <InputLabel htmlFor='email'>Почта</InputLabel>
                          <Input id='email' value={model.email} onChange={this.handleChange('email')}/>
                      </FormControl>
                      <FormControl className={classes.formControl}>
                          <InputLabel htmlFor='name'>Имя</InputLabel>
                          <Input id='name' value={model.name} onChange={this.handleChange('name')}/>
                      </FormControl>
                      <FormControlLabel
                          control={
                              (<Switch
                                  checked={model.disabled}
                                  onChange={this.handleBooleanChange('disabled')}
                                  value={model.disabled ? model.disabled.toString() : "false"}
                              />)
                          }
                          label="Неактивен"
                          labelPlacement="end"
                      />
                      <FormControl className={classes.formControl}>
                              <InputLabel htmlFor={'role-pick'}> Роль</InputLabel>
                              <Select
                                  value={this.state.roleSelector}
                                  onChange={this.handleRoleSelectorChange}
                              >
                                  {
                                      Object.keys(Roles).map((key) => (
                                          <MenuItem value={key}>{Roles[key]}</MenuItem>
                                      ))
                                  }
                              </Select>
                      </FormControl>
                      <FormControl className={classes.formControl}>
                          <InputLabel htmlFor='name'>Пароль</InputLabel>
                          <Input
                              id='password'
                              value={get(model, ['password', 'main'], '')}
                              type={'password'}
                              onChange={this.handlePasswordMain}
                          />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                          <InputLabel htmlFor='name'>Пароль повторно</InputLabel>
                          <Input
                              id='password2'
                              value={get(model, ['password', 'confirm'], '')}
                              type={'password'}
                              onChange={this.handlePasswordConfirm}
                          />
                      </FormControl>
                  </FormControl>}
              </form>
          </div>
        );
    }
}

export default withStyles(styles)(CreateUserForm)
