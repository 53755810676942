import {Theme} from "@material-ui/core";
import * as React from 'react';
import {MouseEvent, ReactNode} from 'react';
import {createStyles, makeStyles} from "@material-ui/styles";
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from "classnames";
import {LayoutState} from "../../store/layout/types";
// Icons
import {NavLink, withRouter} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {AppState} from "../../store";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {closeDrawer, openDrawer} from "../../store/layout/actions";
import {RouteElem, Routes} from "../../route/RouteElem";
import {Metadata} from "../../utils/Metadata";
import {ProfileState} from "../../store/profile/types";
import {UserAccountState} from "../../store/security/types";
import {userLogoutAction} from "../../store/security/actions";
import {push} from "connected-react-router";
import Menu from "@material-ui/core/Menu";
import {AccountCircle} from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        height: `100vh`,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing() * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing() * 9,
        },
    },
    grow: {
        flexGrow: 1,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarButtonsContainer: {
        marginRight: '24px'
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing() * 3,
        overflowY: 'auto'
    },
    current: {},
    link: {
        textDecoration: 'none'
    }}),
);

interface DispatchProps {
    onOpenDrawer: typeof openDrawer;
    onCloseDrawer: typeof closeDrawer;
    onLogout: typeof userLogoutAction;
    navigateTo: (path: string) => any;
}

interface WithRouter {
    history: any;
}

interface StateProps {
    layout: LayoutState;
    userAccount?: UserAccountState;
    profile?: ProfileState;
}

interface NavBarProps extends WithRouter, DispatchProps, StateProps {
    children: ReactNode
}

interface NavBarState {
    anchorEl?: any
}

interface ActivityProps {
    handleClose: () => void,
    handleMenu: (e: MouseEvent<HTMLElement>) => void
}

function UIDrawer(props: NavBarProps & NavBarState & ActivityProps) {
    const {
        layout, children, onOpenDrawer, profile, onCloseDrawer, onLogout, anchorEl, handleMenu, handleClose
    } = props;
    const classes = useStyles(props);
    const menuRoutes = Routes.menuGroupRoutes(Metadata.MenuGroups.sideMenuGroup, (!!profile && !!profile.roles) ? profile.roles : []);
    const open = Boolean(anchorEl);

    const handleProfile = (event: MouseEvent<HTMLElement>) => {
        props.history.push("/profile");
    };
    return(
        <div className={classes.root}>
            <AppBar
                position="absolute"
                className={classNames(classes.appBar, layout.sideBarOpened && classes.appBarShift)}
            >
                <Toolbar disableGutters={!layout.sideBarOpened}>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={onOpenDrawer}
                        className={classNames(classes.menuButton, layout.sideBarOpened && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography color="inherit" className={classes.grow} noWrap>
                        LiqWiz замеры качества воды
                    </Typography>
                    {(!!profile) && (
                        <div className={classes.toolbarButtonsContainer}>
                            <IconButton
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleProfile}>Мой профиль</MenuItem>
                                <MenuItem onClick={() => onLogout()}>Выход</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: classNames(classes.drawerPaper, !layout.sideBarOpened && classes.drawerPaperClose),
                }}
                open={layout.sideBarOpened}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={onCloseDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                {menuRoutes.map((route: RouteElem, index) => {
                    return (
                        <NavLink key={index} exact={true} activeClassName={classes.current} className={classes.link} to={route.path} >
                            <ListItem button={true}>
                                {!!route.icon &&
                                (<ListItemIcon>
                                    {route.icon!()}
                                </ListItemIcon>)
                                }
                                <ListItemText primary={route.label} />
                            </ListItem>
                        </NavLink>
                    );
                })}
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {children}
            </main>
        </div>

    );
}

class ConnectedUIDrawer extends React.Component<NavBarProps, NavBarState> {
    constructor(props: NavBarProps, context: any) {
        super(props, context);
        this.state = {
            anchorEl: null
        }
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleMenu = (event: MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    render() {
        const p = {
            ...this.props,
            ...this.state,
            ...{
                handleClose: this.handleClose.bind(this),
                handleMenu: this.handleMenu.bind(this),
            }
        };
        return <UIDrawer {...p}/>
    };

}

function mapStateToProps(state: AppState): StateProps {
    return {
        layout: state.layout,
        userAccount: state.auth,
        profile: state.profile
    };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {...bindActionCreators({
            onOpenDrawer: openDrawer,
            onCloseDrawer: closeDrawer,
            onLogout: userLogoutAction,
            navigateTo: (path: string) => push(path)
        }, dispatch)};
}
// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedUIDrawer));
