import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader'
import registerServiceWorker from './registerServiceWorker';
import AppRouter from "./route";
import {MuiThemeProvider} from "@material-ui/core/styles";
import './utils/i18n';
import configureStore from "./configureStore";
import './index.css';
import 'material-design-icons/iconfont/material-icons.css';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import history from "./History";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {createMuiTheme, CssBaseline} from "@material-ui/core";
import {Provider, ReactReduxContext} from "react-redux";

const theme = createMuiTheme({
    palette: {
        type: "light",
        background: {
            default: "#fff",
        },
    },
});

export const store = configureStore(history);

const Application = (
    <AppContainer>
        <Provider store={store} context={ReactReduxContext}>
            <MuiThemeProvider theme = {theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <CssBaseline />
                    <AppRouter history={history}/>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </Provider>
    </AppContainer>
);

const render  = () => {
    ReactDOM.render(
        Application,
        document.getElementById("root") as HTMLElement,
    );
};

render();
registerServiceWorker();

