export const Metadata = {
    MenuGroups: {
        sideMenuGroup: 'sideMenu',
        topMenuGroup: 'topMenu'
    },
    Roles: {
        admin: 'ADMIN',
        operator: 'OPERATOR'
    },
    HttpHeaders: {
        XAuthUser: 'X-Auth-UserInfo',
        XAuthToken: 'X-Auth-Token',
        Authentication: 'Authentication'
    }
};