import {EntitiesActionTypes, EntitiesModelState} from "./types";
import {action, PayloadMetaAction} from "typesafe-actions";
import {ErrorDataContainer} from "../../model/ErrorDataContainer";

export interface EntityActionMeta {
    dataKey: keyof EntitiesModelState
}

export interface EntityActionPayload {
    id?: any,
    filter?: any,
    entity?: any,
    listData?: any[],
    error?: ErrorDataContainer
}

export type EntityAction = PayloadMetaAction<EntitiesActionTypes, EntityActionPayload, EntityActionMeta>

export const fetchAllListAction = (modelKey: keyof EntitiesModelState) => {
    return action(EntitiesActionTypes.FETCH_ALL, {}, {dataKey: modelKey});
};
export const fetchFilteredListAction = (modelKey: keyof EntitiesModelState, filter: any) => {
    return action(EntitiesActionTypes.FETCH_FILTERED, {filter}, {dataKey: modelKey});
};
export const fetchFilteredListSuccessAction = (modelKey: keyof EntitiesModelState, listData: any[]) => {
    return action(EntitiesActionTypes.FETCH_FILTERED_SUCCESS, { listData }, {dataKey: modelKey});
};
export const fetchFilteredListFailureAction = (modelKey: keyof EntitiesModelState, error: ErrorDataContainer) => {
    return action(EntitiesActionTypes.FETCH_FILTERED_FAILURE, { error }, {dataKey: modelKey});
};
export const listFetchSuccessAction = (modelKey: keyof EntitiesModelState, data: any[]) => {
    return action(EntitiesActionTypes.LIST_FETCH_SUCCESS, {listData: data}, {dataKey: modelKey});
};
export const listFetchErrorAction = (modelKey: keyof EntitiesModelState, error: ErrorDataContainer) => {
    return action(EntitiesActionTypes.LIST_FETCH_FAILED, {error}, {dataKey: modelKey});
};
export const fetchKeyedEntityAction = (modelKey: keyof EntitiesModelState, id: any) => {
    return action(EntitiesActionTypes.FETCH_BY_ID, {id}, {dataKey: modelKey});
};
export const fetchKeyedEntitySuccessAction = (modelKey: keyof EntitiesModelState, entity?: any) => {
    return action(EntitiesActionTypes.SINGLE_FETCH_SUCCESS, entity ? {entity} : {}, {dataKey: modelKey});
};
export const fetchKeyedEntityErrorAction = (modelKey: keyof EntitiesModelState, error: ErrorDataContainer) => {
    return action(EntitiesActionTypes.SINGLE_FETCH_FAILED, { error }, {dataKey: modelKey});
};
export const createItemAction = (modelKey: keyof EntitiesModelState, entity?: any) => {
    return action(EntitiesActionTypes.CREATE_ITEM, entity ? {entity} : {}, {dataKey: modelKey});
};
export const editItemAction = (modelKey: keyof EntitiesModelState, entity?: any) => {
    return action(EntitiesActionTypes.EDIT_ITEM, entity ? {entity} : {}, {dataKey: modelKey});
};
export const editCancelAction = (modelKey: keyof EntitiesModelState) => {
    return action(EntitiesActionTypes.EDIT_CANCEL, {}, {dataKey: modelKey});
};
export const saveItemAction = (modelKey: keyof EntitiesModelState, entity: any) => {
    return action(EntitiesActionTypes.SAVE_ITEM, {entity},{dataKey: modelKey});
};
export const saveItemSuccessAction = (modelKey: keyof EntitiesModelState, entity: any) => {
    return action(EntitiesActionTypes.SAVE_SUCCESS, {entity}, {dataKey: modelKey});
};
export const saveItemErrorAction = (modelKey: keyof EntitiesModelState, error: ErrorDataContainer) => {
    return action(EntitiesActionTypes.SAVE_FAILED, {error}, {dataKey: modelKey});
};
export const updateItemAction = (modelKey: keyof EntitiesModelState, entity: any) => {
    return action(EntitiesActionTypes.UPDATE_ITEM, {entity}, {dataKey: modelKey});
};
export const removeItemAction = (modelKey: keyof EntitiesModelState, id: any) => {
    return action(EntitiesActionTypes.REMOVE, {id}, {dataKey: modelKey});
};
